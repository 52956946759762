.css-cz6xlx {
  justify-content: center;
}
th > div {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: wrap !important;
}
th > div > div > div {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: wrap !important;
}

td > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: wrap !important;
}
