/*
/*
@File: AndShop Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>
*******************************************
*/

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  /* font-family: "Anton", sans-serif; */
}

img {
  max-width: 100%;
  height: auto;
}

.payment_method .collapsed {
  width: 100% !important;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-100 {
  margin-bottom: 100px;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0.1s;
  transition: all 0.3s ease-in-out 0.1s;
  outline: 0 !important;
  color: var(---heading-color);
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: var(--main-theme-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

h1 {
  font-size: 80px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 22px;
  font-weight: 600;
}

h5 {
  font-size: 18px;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 400;
}

p {
  font-size: 16px;
  line-height: 24px;
  color: var(--paragraph-color);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  padding: 0;
  margin-left: 15px;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-image: url("../img/common/header.jpg");
  opacity: 0.98;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.textshadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45); /* yatay, dikey, bulanıklık, renk */
}

/* --Common Class Area start-- */

/* .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1440px;
} */

.img-zoom-hover img {
  /* transform-origin: 65% 75%; */
  transition: transform 1s, filter 0.5s ease-out;
}

.img-zoom-hover:hover img {
  transform: scale(1.1) rotate(1deg);
}

/* --Center Heading-- */
.center_heading {
  padding-bottom: 30px;
  text-align: center;
}

.center_heading h2 {
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
}

.center_heading p {
  padding-top: 10px;
}

/* --Center Heading Two-- */
.center_heading_two {
  padding-bottom: 30px;
  text-align: center;
}

.center_heading_two h2 {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.heading_border {
  display: block;
  background: var(--main-theme-color);
  height: 3px;
  width: 150px;
  margin: 10px auto 0 auto;
}

/* --Left Heading Three-- */
.left_heading_three {
  text-align: center;
}

.left_heading_three h2 {
  text-transform: uppercase;
  position: relative;
}

/* --Slider Arrow Style--*/

.slider_arrows_one .owl-nav {
  top: -45px;
  position: absolute;
  right: 0;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev {
  background: var(--main-theme-color);
  color: #fff;
  border: none;
  padding: 0 !important;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  box-shadow: 0px 1px 3px -1px #000;
  transition: 0.4s all ease-in-out;
}

.slider_arrows_one .owl-carousel .owl-nav button.owl-next:hover,
.slider_arrows_one .owl-carousel .owl-nav button.owl-prev:hover {
  background: #fff;
  color: #000;
}

/* --Button-- */
.bg-gray {
  background: #f7f7f7;
}

.bg-whites {
  background-color: #fff;
  color: #000;
}

.bg-whites:hover {
  color: #fff;
  background-color: #000;
}

.bg-black {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}

.bg-black:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.theme-btn-one {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  border-radius: 0;
}
.btn_lg {
  padding: 26px 44px;
}
.btn_md {
  padding: 13px 35px;
}

.btn_sm {
  padding: 9px 16px;
}

.btn-black-overlay {
  background-color: #000;
  color: #fff;
}

.btn-black-overlay:hover {
  background-color: #262626;
  color: #fff;
}

#common_banner_one {
  background-image: url("../img/common/header.jpg");
  padding: 120px 0px 90px 0px;
  background-repeat: repeat;
}

.common_banner_text {
  text-align: center;
  color: #fff;
}

.common_banner_text h2 {
  color: #fff;
}

.common_banner_text ul {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.common_banner_text ul li {
  color: #fff;
}

.common_banner_text ul li.active {
  color: var(--main-theme-color);
}

.common_banner_text ul li.slash {
  padding: 0 10px;
}

.common_banner_text ul li a {
  color: #fff;
}

.common_banner_text ul li a:hover {
  color: var(--main-theme-color);
}

/* --Common Class Area start-- */

/*--Common input style--*/

select {
  min-height: 30px;
  background: #fff;
  border-color: #ddd;
  border: 1px solid #ddd;
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="date"] {
  min-height: 30px;
  background: #fff;
  border-color: #ddd;
  border: 1px solid #ddd;
}

textarea {
  background: #fff !important;
  border-color: #ddd !important;
  border: 1px solid #ddd !important;
}

.form-control:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
  background-color: #fff;
  outline: none;
  box-sizing: none;
  border: 1px solid var(--main-theme-color) !important;
}

/* ------- --------------- */

/*Layout*/

/*****************************
* Layout - Header
*****************************/

.header-top-bg--black {
  background: #24262b;
}

.header-top-bg--white {
  background: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-top-contact a,
.header-top-user-link a {
  line-height: 40px;
  margin-right: 40px;
  position: relative;
}

.header-top-contact a::after,
.header-top-user-link a::after {
  position: absolute;
  content: "|";
  left: calc(100% + 20px);
}

.header-top-contact a:last-child,
.header-top-user-link a:last-child {
  margin-right: 0;
}

.header-top-contact a:last-child::after,
.header-top-user-link a:last-child::after {
  display: none;
}

.header-top-contact-color--white a,
.header-top-user-link-color--white a {
  color: #fff;
}

.header-top-contact-color--white a::after,
.header-top-user-link-color--white a::after {
  color: #fff;
}

.header-top-contact-color--black a,
.header-top-user-link-color--black a {
  color: #24262b;
}

.header-top-contact-color--black a::after,
.header-top-user-link-color--black a::after {
  color: #24262b;
}

.header-top-contact-hover-color--green a:hover,
.header-top-user-link-hover-color--green a:hover {
  color: #047b42;
}

.header-top-contact-hover-color--aqua a:hover,
.header-top-user-link-hover-color--aqua a:hover {
  color: #66cec4;
}

.header-bottom {
  position: relative;
}

.header-bottom-color--golden {
  background: #fef5ef;
}

.header-bottom-color--white {
  background: #fff;
}

.header-bottom-color--black {
  background: #24262b;
}

.sticky-header.sticky.sticky-color--golden {
  background: #fef5ef;
}

.sticky-header.sticky.sticky-color--white {
  background: #fff;
}

.sticky-header.sticky.sticky-color--black {
  background: #24262b;
}

.search_width img {
  width: 28px;
}

/* Top Header Style */
#top_header {
  background-color: #ebebeb;
  padding: 9px 0px;
}

.top_header_left p {
  font-size: 14px;
  color: #0a0d14;
}

.top_header_left a {
  color: var(--main-theme-color);
  padding-left: 5px;
}

.right_list_fix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right_list_fix li {
  padding-left: 26px;
}

.right_list_fix li:first-child {
  padding-left: 0;
}

.top_header_right ul li a {
  font-size: 14px;
}

.top_header_right ul li i {
  padding-right: 3px;
}

/*Main Menu Style*/

.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background: #000000;

  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.main-menu {
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.main-menu nav > ul > li {
  position: relative;
  display: inline-block;
  padding-left: 2px;
}

.main-menu nav > ul > .has-dropdown {
  margin-right: 2px;
}

.main-menu nav > ul > .has-dropdown:last-child {
  margin-right: 0;
}

.main-menu nav > ul > .has-megaitem {
  position: static;
}

.main-menu nav > ul > li > a {
  display: block;
  line-height: 24px;
  padding: 12px 15px;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
}

.main-menu-style-4 nav > ul > li > a {
  padding: 10px 30px;
}

.sub-menu > li > a,
.mega-menu-sub > li > a {
  font-weight: 500;
  display: block;
  line-height: 35px;
  text-transform: capitalize;
  color: #777;
  position: relative;
  transition: all 0.5s ease;
}

.sub-menu > li > a:hover,
.mega-menu-sub > li > a:hover {
  transform: translateX(10px);
}

.main-menu .active {
  color: var(--main-theme-color) !important;
}

.sub-menu {
  position: absolute;
  min-width: 170px;
  padding: 12px 20px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu {
  position: absolute;
  min-width: 100%;
  padding: 25px 30px 30px 30px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu-inner {
  display: flex;
  justify-content: space-between;
}

.mega-menu-item-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
  color: #24262b;
}

.mega-menu-sub > li {
  display: block;
}

.main-menu nav > ul > li:hover .sub-menu,
.main-menu nav > ul > li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.menu-banner > a {
  display: block;
  width: 100%;
}

.menu-banner img {
  width: 100%;
}

/*Main Menu Link Normal Color*/

.menu-color--black nav > ul > li > a {
  color: #24262b;
}

.menu-color--black .sub-menu > li > a,
.menu-color--black .mega-menu-sub > li > a {
  color: #24262b;
}

.menu-color--black .mega-menu-item-title {
  color: #24262b;
}

.menu-color--white nav > ul > li > a {
  color: #fff;
}

.menu-color--white .sub-menu > li > a,
.menu-color--white .mega-menu-sub > li > a {
  color: #24262b;
}

.menu-color--white .mega-menu-item-title {
  color: #24262b;
}

/*Main Menu Link Hover Color*/

.menu-hover-color--golden nav > ul > li > a:hover {
  color: var(--main-theme-color);
}

.menu-hover-color--golden .sub-menu > li > a:hover,
.menu-hover-color--golden .mega-menu-sub > li > a:hover {
  color: var(--main-theme-color);
}

/* .menu-hover-color--golden .mega-menu-item-title:hover {
    color: var(--main-theme-color);
} */

.menu-hover-color--green nav > ul > li > a:hover {
  color: #047b42;
}

.menu-hover-color--green .sub-menu > li > a:hover,
.menu-hover-color--green .mega-menu-sub > li > a:hover {
  color: #047b42;
}

.menu-hover-color--green .mega-menu-item-title:hover {
  color: #047b42;
}

.menu-hover-color--pink nav > ul > li > a:hover {
  color: #ff365d;
}

.menu-hover-color--pink .sub-menu > li > a:hover,
.menu-hover-color--pink .mega-menu-sub > li > a:hover {
  color: #ff365d;
}

.menu-hover-color--pink .mega-menu-item-title:hover {
  color: #ff365d;
}

.menu-hover-color--aqua nav > ul > li > a:hover {
  color: #66cec4;
}

.menu-hover-color--aqua .sub-menu > li > a:hover,
.menu-hover-color--aqua .mega-menu-sub > li > a:hover {
  color: #66cec4;
}

.menu-hover-color--aqua .mega-menu-item-title:hover {
  color: #66cec4;
}

.header-action-link {
  display: flex;
  align-items: center;
}

.header-action-link li a {
  font-size: 18px;
  position: relative;
}

.header-action-link li a span {
  display: inline-block;
  font-size: 13px;
  background: darkgoldenrod;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
  font-weight: 600;
}

.offside-about {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  letter-spacing: normal !important;
  display: block;
  border-radius: 50%;
}

.offcanvas-close img {
  height: 18px;
}

/*Action Style Color Normal*/

.action-color--black.header-action-link li a {
  color: #24262b;
}

.action-color--black .offside-about {
  background: rgba(242, 232, 231, 0.5);
}

.action-color--white.header-action-link li a {
  color: #fff;
}

.action-color--white .offside-about {
  background: rgba(255, 255, 255, 0.05);
}

/*Action Style Color Hover*/

.action-hover-color--golden.header-action-link li a:hover {
  color: var(--main-theme-color);
}

.action-hover-color--golden.header-action-link li a span {
  background: var(--main-theme-color);
}

.action-hover-color--golden .offside-about:hover {
  background: #fff;
}

.action-hover-color--green.header-action-link li a:hover {
  color: #047b42;
}

.action-hover-color--green.header-action-link li a span {
  background: #047b42;
}

.action-hover-color--green .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--pink.header-action-link li a:hover {
  color: #ff365d;
}

.action-hover-color--pink.header-action-link li a span {
  background: #ff365d;
}

.action-hover-color--pink .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.action-hover-color--aqua.header-action-link li a:hover {
  color: #66cec4;
}

.action-hover-color--aqua.header-action-link li a span {
  background: #66cec4;
}

.action-hover-color--aqua .offside-about:hover {
  background: rgba(255, 255, 255, 0.2);
}

.seperate-sticky-bar {
  display: none;
}

.seperate-sticky-bar.sticky {
  display: block;
}

.inner-search {
  right: -15px;
  width: 330px;
  background: #fff;
  display: none;
}

.inner-search input[type="search"] {
  width: 100%;
  padding: 15px 50px 15px 10px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
}

.inner-search button {
  right: 0;
  background: aliceblue;
  padding: 10px 14px;
  top: 50%;
  transform: translateY(-50%);
}

.search-click {
  cursor: pointer;
}

/*****************************
* Layout - Header Mobile
*****************************/

.mobile-menu-logo li {
  margin-right: 15px;
}

.mobile-menu-logo li:last-child {
  margin-right: 0;
}

.mobile-menu-logo {
  display: flex;
  align-items: center;
}

.mobile-menu-logo .logo {
  width: 100px;
  overflow: hidden;
}

.mobile-header {
  padding: 15px 0;
}

.offside-menu {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: normal !important;
  display: block;
  border-radius: 50%;
  background: rgba(242, 232, 231, 0.5);
}

.offside-menu-color--black {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.offside-menu-hover-color--golden:hover {
  color: var(--main-theme-color);
}

.offside-menu-hover-color--green:hover {
  color: #047b42;
}

.offside-menu-hover-color--pink:hover {
  color: #ff365d;
}

.offside-menu-hover-color--aqua:hover {
  color: #66cec4;
}

.mobile-header-bg-color--golden {
  background: #fef5ef;
}

.mobile-header-bg-color--white {
  background: #fff;
}

.mobile-header-bg-color--black {
  background: #24262b;
}

/*****************************
* Layout - Footer
*****************************/

.footer-bg {
  background: #24262b;
  padding-top: 60px;
}

@media (min-width: 768px) {
  .footer-bg {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .footer-bg {
    padding-top: 100px;
  }
}

/*Footer Top*/

.footer-top .footer-widget-single-item .title {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 23px;
  text-transform: uppercase;
}

.footer-top .footer-widget-single-item .title::after {
  content: "";
  width: 50px;
  height: 2px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.footer-top .footer-widget-single-item .footer-nav li {
  margin-bottom: 8px;
  display: block;
}

.footer-top .footer-widget-single-item .footer-nav li:last-child {
  margin-bottom: 0;
}

.footer-top .footer-widget-single-item .footer-nav li a {
  display: inline-block;
  color: #9b9b97;
  transition: all 0.5s ease;
}

.footer-top .footer-widget-single-item .footer-nav li a:hover {
  color: #fff;
  transform: translateX(10px);
}

.footer-top .footer-widget-color--golden .title::after {
  background: var(--main-theme-color);
}

.footer-top .footer-widget-color--green .title::after {
  background: #047b42;
}

.footer-top .footer-widget-color--aqua .title::after {
  background: #66cec4;
}

.footer-top .footer-widget-color--pink .title::after {
  background: #ff365d;
}

/*Footer Center*/

.footer-center {
  margin-top: 40px;
  padding: 60px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

@media (min-width: 768px) {
  .footer-center {
    margin-top: 55px;
  }
}

.footer-social .title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.footer-social-link li {
  display: inline-block;
  margin-right: 20px;
}

.footer-social-link li:last-child {
  margin-right: 0;
}

.footer-social-link li a {
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #2f3239;
  color: #fff;
  transition: all 0.5s ease;
  font-size: 16px;
}

.footer-social-link li a:hover {
  background: #fff;
  color: #2f3239;
}

.footer-newsletter .title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 27px;
  text-transform: uppercase;
}

.form-fild-newsletter-single-item {
  position: relative;
}

.form-fild-newsletter-single-item input {
  width: 100%;
  border: 2px solid #9b9b97;
  border-radius: 5px;
  padding: 10px 145px 10px 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease;
}

.form-fild-newsletter-single-item button {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.5s ease;
}

.form-fild-newsletter-single-item.input-color--golden input:focus {
  border-color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--golden button {
  background: var(--main-theme-color);
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--golden button:hover {
  background: #fff;
  color: var(--main-theme-color);
}

.form-fild-newsletter-single-item.input-color--green input:focus {
  border-color: #047b42;
}

.form-fild-newsletter-single-item.input-color--green button {
  background: #047b42;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--green button:hover {
  background: #fff;
  color: #047b42;
}

.form-fild-newsletter-single-item.input-color--aqua input:focus {
  border-color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--aqua button {
  background: #66cec4;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--aqua button:hover {
  background: #fff;
  color: #66cec4;
}

.form-fild-newsletter-single-item.input-color--pink input:focus {
  border-color: #ff365d;
}

.form-fild-newsletter-single-item.input-color--pink button {
  background: #ff365d;
  color: #fff;
}

.form-fild-newsletter-single-item.input-color--pink button:hover {
  background: #fff;
  color: #ff365d;
}

/*Footer Bottom*/

.footer-bottom {
  padding-top: 35px;
  padding-bottom: 35px;
}

.footer-copyright {
  text-transform: uppercase;
}

.footer-copyright p {
  margin-bottom: 0;
}

.footer-copyright a {
  color: #fff;
}

/*****************************
* Layout - Offcanvas
*****************************/

.offcanvas {
  z-index: 999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.5s ease;
  transform: translateX(100%);
  box-shadow: none;
  background: #fff;
  width: 300px;
  overflow-x: hidden;
}

@media (min-width: 576px) {
  .offcanvas {
    width: 450px;
  }
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 40px;
  height: 40px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.offcanvas-close:hover {
  color: red;
}

.offcanvas-title {
  margin-bottom: 40px;
}

.offcanvas-mobile-menu-section,
.offcanvas-mobile-about-section {
  background: #24262b !important;
}

.offcanvas-mobile-menu-section .offcanvas-close,
.offcanvas-mobile-about-section .offcanvas-close {
  color: #fff;
}

/*Mobile Menu Bottom Area*/

.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 7px 5px;
}

.offcanvas-menu > ul > li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu > ul > li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}

.offcanvas-menu li a.active {
  color: var(--main-theme-color);
}

.offcanvas-menu li a:hover {
  color: var(--main-theme-color);
}

.offcanvas-menu-expand::after {
  position: absolute;
  right: 0;
  transition: all 0.3s ease;
  color: #fff;
  font-family: "Font Awesome 5 Free";
  content: "\f0dd";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.offcanvas-menu .active > .offcanvas-menu-expand:last-child,
.sub-menu > .active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active > .offcanvas-menu-expand::after,
.sub-menu > .active .offcanvas-menu-expand::after {
  color: var(--main-theme-color);
  transform: rotate(90deg);
}

/*Mobile Menu Contact Info*/

.mobile-contact-info {
  text-align: center;
  margin: 0 auto;
  padding-top: 65px;
}

.mobile-contact-info .logo {
  margin: 0 auto;
  width: 200px;
}

.mobile-contact-info .logo img {
  width: 100%;
  height: auto;
}

.mobile-contact-info .address {
  margin-top: 30px;
  color: #fff;
}

.mobile-contact-info .address span {
  display: block;
}

.mobile-contact-info .social-link li {
  display: inline-block;
  margin-right: 20px;
}

.mobile-contact-info .social-link li:last-child {
  margin-right: 0;
}

.mobile-contact-info .social-link li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #2f3239;
  color: #fff;
  transition: all 0.5s ease;
  font-size: 16px;
}

.mobile-contact-info .social-link li a:hover {
  background: #fff;
  color: #2f3239;
}

.mobile-contact-info .user-link {
  margin-top: 30px;
}

.mobile-contact-info .user-link li {
  display: inline-block;
  margin-right: 15px;
}

.mobile-contact-info .user-link li:last-child {
  margin-right: 0;
}

.mobile-contact-info .user-link li a {
  color: #fff;
  text-decoration: underline;
}

.mobile-contact-info .user-link li a:hover {
  color: var(--main-theme-color);
}

.offcanvas-cart-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-cart-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-cart-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-cart-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #ededed;
  margin-right: 20px;
}

.offcanvas-cart-image {
  width: 100%;
}

.offcanvas-cart-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-cart-item-link:hover {
  color: #24262b;
}

.offcanvas-cart-total-price {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.offcanvas-cart-total-price-text {
  font-weight: 700;
}

.offcanvas-cart-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-cart-item-delete {
  color: red;
}

.offcanvas-cart-item-delete:hover {
  color: red;
}

.offcanvas-cart-action-button-link {
  display: block;
  background: #24262b;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  border: 1px border transparent;
}

.offcanvas-cart-action-button-link:hover {
  background: #fff;
  color: #24262b;
  border: 1px border #24262b !important;
}

.offcanvas-wishlist-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-wishlist-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-wishlist-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #ededed;
  margin-right: 20px;
}

.offcanvas-wishlist-image {
  width: 100%;
}

.offcanvas-wishlist-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-wishlist-item-link:hover {
  color: #24262b;
}

.offcanvas-wishlist-total-price-text {
  font-weight: 700;
}

.offcanvas-wishlist-action-button {
  margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-wishlist-item-delete {
  color: red;
}

.offcanvas-wishlist-item-delete:hover {
  color: red;
}

.offcanvas-wishlist-action-button-link {
  display: block;
  background: #24262b;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px border transparent;
}

.offcanvas-wishlist-action-button-link:hover {
  background: #24262b;
  color: #fff;
  border: 1px border #24262b !important;
}

.search-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(-100%, 0);
  opacity: 0;
  z-index: 99999;
}

.search-modal.open {
  transform: translate(0px, 0px);
  opacity: 1;
}

.search-modal input[type="search"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  color: white;
  background: rgba(0, 0, 0, 0);
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 10px;
  outline: none;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

.search-modal input[type="search"]:focus {
  border-color: var(--main-theme-color);
}

.search-modal .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

.search-modal .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  opacity: 1;
  width: 40px;
  background: #fff;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  font-size: 0;
}

/*****************************
* Layout - Modal
*****************************/

.modal {
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  padding-right: 0 !important;
}

.modal-dialog {
  max-width: calc(100% - 20px) !important;
  margin: 0 auto !important;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 550px !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 550px !important;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 550px !important;
  }
}

.modal-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal-close {
  width: 40px;
  height: 40px;
  border: 1px solid #777;
  color: #777;
  margin-bottom: 20px;
  border-radius: 3px;
}

.modal-close:hover {
  color: var(--main-theme-color);
  border-color: var(--main-theme-color);
}

@media (min-width: 768px) {
  .modal-close {
    margin-bottom: 0px;
  }
}

.modal-border {
  border-left: none;
}

@media (min-width: 992px) {
  .modal-border {
    border-left: 1px solid #ededed;
  }
}

.modal-content {
  border: none;
}

.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.modal-add-cart-product-img {
  text-align: center;
}

.modal-add-cart-product-title {
  margin-top: 25px;
}

@media (min-width: 768px) {
  .modal-add-cart-product-title {
    margin-top: 0px;
  }
}

.modal-add-cart-product-title,
.modal-add-cart-product-price {
  display: block;
  font-weight: 500;
}

.modal-add-cart-info {
  margin-top: 40px;
}

@media (min-width: 768px) {
  .modal-add-cart-info {
    margin-top: 0;
  }
}

.modal-add-cart-product-info,
.modal-add-cart-product-shipping-info {
  font-weight: 500;
}

.modal-add-cart-product-info li,
.modal-add-cart-product-shipping-info li {
  margin-bottom: 10px;
}

.modal-add-cart-product-info li:last-child,
.modal-add-cart-product-shipping-info li:last-child {
  margin-bottom: 0;
}

.modal-add-cart-product-info span,
.modal-add-cart-product-shipping-info span {
  font-weight: 300;
  padding-left: 5px;
}

.modal-add-cart-product-cart-buttons {
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .modal-add-cart-product-cart-buttons {
    flex-direction: row;
  }
}

.modal-add-cart-product-cart-buttons a {
  margin-top: 10px;
  margin-bottom: 10px;
  background: var(--main-theme-color);
  color: white;
  padding: 5px 10px;
  margin-right: 0px;
  text-align: center;
}

.modal-add-cart-product-cart-buttons a:last-child {
  margin-right: 0;
}

@media (min-width: 576px) {
  .modal-add-cart-product-cart-buttons a {
    margin-top: 20px;
    margin-bottom: 15px;
    margin-right: 10px;
    flex-direction: row;
  }
}

.modal-add-cart-info i {
  color: var(--main-theme-color);
  padding-right: 10px;
  font-size: 16px;
}

.modal-continue-button a {
  text-decoration: underline;
  color: var(--main-theme-color);
}

.modal-continue-button a:hover {
  text-decoration: none;
}

.modal-product-details-social .title {
  display: block;
  font-size: 16px;
  margin-bottom: 15px;
}

.modal-product-details-social ul {
  display: flex;
  flex-wrap: wrap;
}

.modal-product-details-social li {
  margin-right: 10px;
}

.modal-product-details-social li:last-child {
  margin-right: 0;
}

.modal-product-details-social li a {
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  text-align: center;
  color: #777;
  font-size: 16px;
  border: 1px solid #ededed;
}

.modal-product-about-text {
  border-bottom: 1px solid #ededed;
  margin-bottom: 10px;
}

.product-image-thumb {
  position: relative;
}

.product-image-thumb img {
  border: 1px solid #ededed;
}

.product-image-thumb.modal-product-image-thumb {
  margin-top: 15px;
}

.product-image-thumb.modal-product-image-thumb .slick-list {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.product-image-thumb.modal-product-image-thumb .slick-slide {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.product-image-thumb:hover .gallery-nav {
  visibility: visible;
  opacity: 1;
}

.material-scrolltop {
  z-index: 999;
  display: block;
  position: fixed;
  width: 0;
  height: 0;
  bottom: 23px;
  right: 23px;
  padding: 0;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  cursor: hand;
  border-radius: 50%;
  background: var(--main-theme-color);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0, 1);
}

.material-scrolltop:hover {
  background-color: var(--main-theme-color);
  text-decoration: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5), 0 3px 15px rgba(0, 0, 0, 0.5);
}

.material-scrolltop::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 0;
  border-radius: 100%;
  background: #bda379;
}

.material-scrolltop:active::before {
  width: 120%;
  padding-top: 120%;
  transition: all 0.2s ease-out;
}

.material-scrolltop.reveal {
  width: 56px;
  height: 56px;
}

.material-scrolltop span {
  display: block;
  font-size: 25px;
  color: #fff;
}

.offcanvas-cart-action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offcanvas-cart-action-button li {
  width: 100%;
}

.address img {
  padding-bottom: 20px;
}

.mobile-contact-info .social-link {
  padding-top: 5px;
}

/* --Fashion--- */

/* -------------------------
   Banner Area 
---------------------------*/

#banner_one {
  z-index: 10;
  position: relative;
  padding: 230px 0 0 0;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.banner_text_one {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.banner_text_one h1 {
  padding: 100px 0 0 0;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-style: italic;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 120px;
}

.banner_text_one h3 {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 600;
}

.banner_text_one a {
  margin-top: 30px;
}

.banner_text_one h1 span {
  display: block;
  font-weight: 100;
}

.hero_img div {
  position: absolute;
  top: -163px;
  right: 0;
}
/* -------------------------
   Banner Area Mobile
---------------------------*/

@media (max-width: 1000px) {
  #banner_one {
    padding: 20px 0 0 0;
  }
  .banner_text_one {
    padding-left: 20px;
  }
  .banner_text_one h1 {
    padding: 20px 0 0 0;
    font-family: "Roboto", sans-serif;
    letter-spacing: 1px;
    font-style: italic;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 40px;
  }
  .banner_text_one a {
    margin-top: 10px;
  }

  .banner_text_one h1 span {
    display: block;

    font-weight: 100;
  }

  .hero_img div {
    position: absolute;
    top: -163px;
    right: 0;
  }
}

/* -------------------------
   Product variation Area 
---------------------------*/

.row .col-lg-4 .product_variation_one_boxed:last-child {
  margin-bottom: 0;
}

.product_variation_one_boxed {
  position: relative;
  overflow: hidden;
}

.product_variation_one_boxed img {
  width: 100%;
}

.product_var_one_text_center {
  position: absolute;
  left: 40px;
  bottom: 0;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.product_var_one_text {
  position: absolute;
  left: 40px;
  top: 50%;
  padding: 20px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50%;
}

.product_var_one_text h2,
.product_var_one_text_center h2 {
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.product_var_one_text h4,
.product_var_one_text_center h4 {
  text-transform: uppercase;
}

.product_var_one_text a,
.product_var_one_text_center a {
  margin-top: 20px;
}

.color_one {
  color: var(--main-theme-color);
}

/* -------------------------
    Hot Product Area 
---------------------------*/

/*  Filers CSS */
.tabs_center_button .nav-tabs {
  border-bottom: none;
  width: 100%;
  justify-content: center;
}

.tabs_center_button .nav-tabs li a {
  margin-left: 50px;
  transition: inherit;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  background: transparent;
  padding-bottom: 5px;
}

.tabs_center_button .nav-tabs li:first-child a {
  margin-left: 0px;
}

.tabs_center_button .nav-tabs li a:hover {
  color: var(--main-theme-color);
}

.tabs_center_button .nav-tabs li .active {
  color: var(--main-theme-color) !important;
  border-bottom: 2px solid var(--main-theme-color);
}

.tabs_mobile_button ul li a:hover {
  border-bottom: 2px solid #000000;
}
/* -------------------------
     Offer Timer One Area 
---------------------------*/

#offer_timer_one {
  background-size: cover;
  background-position: center;
}

.offer_timer_text {
  padding-left: 60px;
}

.offer_timer_text h2 {
  font-size: 40px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.offer_timer_text p {
  padding-top: 15px;
  padding-bottom: 30px;
}

.offer_timer_text_mobile {
  padding-left: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offer_timer_text_mobile h2 {
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.offer_timer_text_mobile p {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 9px;
}
#countdown ul li {
  border: 1px solid #c5c5c5;
  width: 100px;
  height: 70px;
  text-align: center;
  padding-top: 10px;
  margin-top: 30px;
  font-size: 14px;
}

#countdown ul li span {
  display: block;
  font-size: 20px;
  font-weight: 700;
}

#countdown ul li:first-child {
  margin-top: 0;
}

#countdown_mobile ul li {
  border: 1px solid #c5c5c5;
  width: 50px;
  height: 40px;
  text-align: center;
  padding-top: 5px;
  margin-top: 20px;
  font-size: 9px;
}

#countdown_mobile ul li span {
  display: block;
  font-size: 10px;
  font-weight: 700;
}

#countdown_mobile ul li:first-child {
  margin-top: 0;
}
.offer_time_flex {
  display: flex;
}

#offer_timer_mobile {
  background-size: cover;
  background-position: 12%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
/* -------------------------
     To Days Area 
---------------------------*/

.product_wrappers_one {
  overflow: hidden;
}

.product_wrappers_one .thumb {
  position: relative;
  overflow: hidden;
}

.product_wrappers_one .thumb .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.product_wrappers_one .thumb .image img {
  z-index: 1;
  max-width: 100%;
}

.product_wrappers_one .content {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  transition: all 0.3s ease 0s;
  text-align: center;
  background-color: #fff;
}

.product_wrappers_one .content .title a {
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
}

.product_wrappers_one .content .btn {
  align-self: center;
}

/* -------------------------
 Spceial Banner Area 
---------------------------*/

#special_offer_one {
  background-color: #4f4f4f;
}

.offer_banner_one h5 {
  color: "#112F63";
}

.offer_banner_one h2 {
  color: #fff;
  padding: 10px 0;
}

.offer_banner_one p {
  color: #fff;
  padding-bottom: 30px;
}

/* -------------------------
  Instagram Area Start 
---------------------------*/

.instgram_post {
  position: relative;
}

.instgram_post > a {
  display: block;
  position: relative;
  z-index: 1;
}

.instgram_post > a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f3f0ea;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.instgram_post > a i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: scale(1.2) translate(-50%, -50%);
  z-index: 2;
  font-size: 40px;
  color: #262626;
  line-height: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}

.instgram_post > a:hover::before {
  opacity: 0.7;
}

.instgram_post > a:hover i {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.instgram_post img {
  width: 100%;
}

.instagram_post_slider {
  margin-bottom: -7px;
}

/* -------------------------
  Footer Area Start 
---------------------------*/

#footer_one .footer_left_side p {
  padding-top: 15px;
}

#footer_one .footer_left_side h2 {
  padding-top: 15px;
}

#footer_one .footer_left_side_icon {
  padding-top: 0px;
  color: white;
}

#footer_one .footer_left_side_icon ul li {
  display: inline-flex;
  padding-left: 10px;
}

#footer_one .footer_left_side_icon ul li:first-child {
  padding-left: 0px;
}

#footer_one .footer_left_side_icon ul li a i {
  font-size: 18px;
}

#footer_one .footer_left_side p {
  padding-right: 50px;
}

/* --CopyRight Area-- */

#copyright_one {
  padding: 15px 0;
  border-top: 1px solid #ededed;
}

.copyright_left h6 {
  font-size: 14px;
  color: #fff;
}

#copyright_one .copyright_right {
  text-align: right;
}

#copyright_one .copyright_left h6 span {
  font-weight: 600;
  color: var(--main-theme-color);
}

/* -------------------------
  Footer Area End 
---------------------------*/

/* ------------------------------
    Furniture Home Page
--------------------------------- */
/* -------------------------
     Banner Area
---------------------------*/
.bg_1 {
  background-image: url("../img/furniture/banner/banner1.png");
}

.bg_2 {
  background-image: url("../img/furniture/banner/banner2.png");
}

.bg_3 {
  background-image: url("../img/furniture/banner/banner3.png");
}

.background_bg {
  background-position: center center;
  background-size: cover;
}

.furniture_slider {
  padding: 200px 0;
}

.furniture_slider_content h5 {
  opacity: 0;
  -webkit-transition: all 1200ms ease;
  -o-transition: all 1200ms ease;
  transition: all 1200ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content h2 {
  padding: 35px 0;
  font-size: 60px;
  font-weight: 700;
  opacity: 0;
  -webkit-transition: all 1400ms ease;
  -o-transition: all 1400ms ease;
  transition: all 1400ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content a {
  opacity: 0;
  -webkit-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.furniture_slider_content p {
  padding-bottom: 30px;
  opacity: 0;
  -webkit-transition: all 1500ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

#furniture_banner .slick-active .furniture_slider_content h5 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

#furniture_banner .slick-active .furniture_slider_content h2 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

#furniture_banner .slick-active .furniture_slider_content p {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

#furniture_banner .slick-active .furniture_slider_content a {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1700ms;
  -o-transition-delay: 1700ms;
  transition-delay: 1700ms;
}

#furniture_banner .owl-theme .owl-dots {
  position: absolute;
  top: 0;
  right: 50px;
  bottom: 0;
  margin: 0;
  width: 50px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot {
  position: relative;
  display: block;
  width: 30px;
  height: 5px;
  background: var(--main-theme-color);
  border: 0px solid;
  margin: 5px 0px;
  padding: 0px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

#furniture_banner .owl-theme .owl-dots .owl-dot span {
  display: none;
}

#furniture_banner .owl-theme .owl-dots .owl-dot.active {
  width: 40px;
}

#furniture_banner .owl-theme .owl-dots .owl-dot:before {
  position: absolute;
  top: 0px;
  right: -10px;
  bottom: 0px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background: var(--main-theme-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* -------------------------
     Banner Bottom Area
---------------------------*/
.furniture_bottom_wrapper {
  position: relative;
}

.furniture_bottom_wrapper .hover_effect_furniture {
  position: relative;
  display: block;
  outline: none !important;
}

.furniture_bottom_wrapper .hover_effect_furniture::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper:hover > .hover_effect_furniture:before {
  visibility: visible;
  opacity: 1;
}

.furniture_bottom_wrapper .hover_effect_furniture::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(51, 51, 51, 0.4);
  transition: all 0.35s ease;
}

.furniture_bottom_wrapper a img {
  width: 100%;
}

.furniture_bottom_content {
  display: inline-block;
  position: absolute;
  padding-top: 0.4rem;
  left: 2rem;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.furniture_bottom_content a {
  color: #fff;
  border-bottom: 2px solid var(--main-theme-color);
}

.furniture_bottom_content a:hover {
  color: var(--main-theme-color);
}

.furniture-content-lg h5 {
  color: #fff;
}

.furniture-content-lg h2 {
  color: #fff;
  padding: 10px 0;
  line-height: 50px;
}

.furniture-content-md h5 {
  color: #fff;
  line-height: 0;
  padding-bottom: 2px;
  font-size: 16px;
}

.furniture-content-md h2 {
  color: #fff;
  padding: 13px 0;
  line-height: 28px;
  font-size: 22px;
}

/* -------------------------
     Offer Timer One 
---------------------------*/
#offer_timer_two {
  background: #5a6b85;
  padding-top: 100px;
}

.offer_time_two_Content {
  text-align: center;
}

.offer_time_two_Content img {
  width: 60%;
}

/* -------------------------
  Furniture Popular Product
---------------------------*/
#furniture_popular_product .product_box {
  margin: 0px 15px 15px 15px;
}

/* -------------------------
  Our Story Area
---------------------------*/
#furniture_story {
  background-image: url("../img/furniture/common/parallex.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0;
  position: relative;
}

#furniture_story::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  position: absolute;
  top: 0;
}

.our_story_content {
  text-align: center;
  z-index: 9;
  position: relative;
}

.our_story_content p {
  padding: 40px 0;
}

.our_story_content h2,
.our_story_content p {
  color: #fff;
}

/* -------------------------
     Blog Post Area
---------------------------*/
.blog_post_wrapper {
  position: relative;
  overflow: hidden;
}

.blog_post_img {
  position: relative;
}

.blog_post_img a img {
  width: 100%;
  transition: 0.4s all ease-in-out;
}

.blog_post_wrapper:hover img {
  transform: rotate(2deg) scale(1.1);
}

.blog_post_date {
  text-align: center;
  padding: 15px 90px 15px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 76% 0, 100% 100%, 0 100%);
  background: #222;
  color: #fff;
  font-size: 18px;
}

.blog_post_date span {
  font-size: 16px;
}

.right_block {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 370px;
  background: rgba(34, 34, 34, 0.9);
  height: 100%;
  padding: 30px;
}

.right_side_content {
  color: #fff;
}

.right_side_content h5 {
  color: #fff;
  font-size: 24px;
}

.right_side_content p {
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 25px;
  font-size: 14px;
  padding-top: 15px;
}

.right_side_content a {
  color: #fff;
  border-bottom: 1px solid var(--main-theme-color);
}

.right_side_content a:hover {
  color: var(--main-theme-color);
}

/* ------------------------------
    Electronics Home Page
--------------------------------- */

/* --Banner Area-- */
.electronics_slider {
  padding: 300px 0;
}

.electronics_slider_content h5 {
  opacity: 0;
  -webkit-transition: all 1200ms ease;
  -o-transition: all 1200ms ease;
  transition: all 1200ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.electronics_slider_content h2 {
  padding: 35px 0;
  font-size: 60px;
  font-weight: 700;
  opacity: 0;
  -webkit-transition: all 1400ms ease;
  -o-transition: all 1400ms ease;
  transition: all 1400ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.electronics_slider_content h2 span {
  color: var(--main-theme-color);
}

.electronics_slider_content a {
  opacity: 0;
  -webkit-transition: all 1600ms ease;
  -o-transition: all 1600ms ease;
  transition: all 1600ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.electronics_slider_content h4 {
  padding-bottom: 30px;
  opacity: 0;
  -webkit-transition: all 1500ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

#electronics_banner .slick-active .electronics_slider_content h5 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

#electronics_banner .slick-active .electronics_slider_content h2 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1500ms;
  -o-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

#electronics_banner .slick-active .electronics_slider_content h4 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1600ms;
  -o-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

#electronics_banner .slick-active .electronics_slider_content a {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 1700ms;
  -o-transition-delay: 1700ms;
  transition-delay: 1700ms;
}

#electronics_banner .owl-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
}

#electronics_banner .owl-carousel .owl-nav .owl-prev {
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -o-transform: translateX(20px);
  transform: translateX(20px);
}

#electronics_banner .owl-carousel .owl-nav .owl-next {
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  -o-transform: translateX(-20px);
  transform: translateX(-20px);
}

#electronics_banner .owl-carousel .owl-nav .owl-next,
#electronics_banner .owl-carousel .owl-nav .owl-prev {
  height: 30px;
  width: 30px;
  background: var(--main-theme-color);
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 1px 15px 0 rgb(0 0 0 / 10%);
  visibility: hidden;
  transition: 0.4s all ease-in-out;
}

#electronics_banner .owl-carousel:hover .owl-nav .owl-prev,
#electronics_banner .owl-carousel:hover .owl-nav .owl-next {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.el_bg_1 {
  background-image: url("../img/common/bannerumre.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.el_bg_2 {
  background-image: url("../img/common/bannerhac.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.el_bg_3 {
  background-image: url("../img/common/banner1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* --Banner Bottom Area-- */
.el_banner_bottom {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.el_banner_bottom img {
  transition: 0.4s all ease-in-out;
  width: 100%;
}

.el_banner_bottom img:hover {
  transform: scale(1.05);
}

.el_feature_wrappers {
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 16px 20px;
  margin-top: 30px;
}

.el_feature_wrappers .el_feature_box {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

.el_feature_box {
  position: relative;
}

.el_feature_box img {
  width: 45px;
  flex: 0 0 45px;
  margin: 0;
  margin-right: 10px;
}

.el_feature_wrappers .el_feature_text {
  overflow: hidden;
  flex-grow: 1;
}

.el_feature_wrappers .el_feature_text h3 {
  color: #253237;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 22px;
  margin-bottom: 0;
}

.el_feature_wrappers .el_feature_text p {
  line-height: 20px;
  color: #666;
  font-size: 13px;
  margin: 0;
}

/* --Top Product Area-- */
.tabs_right_button .nav-tabs {
  border-bottom: none;
}

.tabs_right_button .nav-tabs li a {
  font-size: 16px;
  font-weight: 600;
  color: var(--paragraph-color);
}

.tabs_right_button .nav-tabs li a:hover {
  color: var(--main-theme-color);
}

.tabs_right_button .nav-tabs li .active {
  background: var(--main-theme-color);
  padding: 5px 22px;
  color: #fff !important;
}

/* --Promotion Banner Area-- */
#promotion_banner {
  background-color: #f7f7f7;
}

.elec_promo_text h2 {
  font-weight: 700;
  line-height: 48px;
  padding-bottom: 20px;
}

.elec_promo_icon {
  margin-top: 40px;
}

.icon_promo_item {
  display: inline-table;
  margin-right: 40px;
}

.icon_promo_item i {
  background: #525252;
  color: #fff;
  text-align: center;
  line-height: 84px;
  font-size: 22px;
  width: 84px;
  height: 84px;
  border-radius: 50%;
}

.icon_promo_item p {
  max-width: 129px;
  margin-bottom: 0;
  margin-top: 18px;
  display: block;
  font-weight: 700;
  text-align: left;
}

.promotion_img {
  text-align: right;
}

.insta_heading_elce {
  padding-bottom: 30px;
}

/* -------------------------
 product_slider_one Modal   
---------------------------*/

#product_slider_one .owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  background: var(--heading-color);
}

#product_slider_one .owl-theme .owl-dots .owl-dot.active span,
#product_slider_one.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color);
  width: 20px;
  height: 6px;
}

.product_one_modal_top .close_modal_icon {
  position: absolute;
  right: 0px;
  z-index: 10;
  top: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  border-radius: 50%;
  transition: 0.3s;
  display: inline-block;
  background: #fff;
  border: 0;
}

.product_one_modal_top .close_modal_icon:hover {
  background: var(--main-theme-color);
  color: #fff;
  transform: rotate(90deg);
}

.modal_product_content_one .reviews_rating {
  padding-top: 10px;
}

.modal_product_content_one .reviews_rating i {
  color: var(--main-theme-color);
}

.modal_product_content_one .reviews_rating span {
  padding-top: 4px;
}

.modal_product_content_one h4 {
  padding-top: 10px;
}

.modal_product_content_one p {
  padding-top: 10px;
}

#product_count_form_one {
  padding-top: 20px;
}

#product_count_form_one input {
  width: 20%;
  min-height: 40px;
  border-radius: 0;
  margin: 0 8px;
  text-align: center;
}

#product_count_form_two {
  padding-top: 20px;
}

#product_count_form_two input {
  width: 20%;
  min-height: 40px;
  border-radius: 0;
  margin: 0 8px;
  text-align: center;
}

.product_count_one a {
  margin-right: 29px;
  margin-top: 30px;
}

.product-variable-size label {
  position: relative;
  padding-right: 8px;
}
.product-variable-size label.disabled span {
  opacity: 0.5;
  cursor: not-allowed;
}

.product-variable-size label input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.product-variable-size label span {
  position: relative;
  width: 35px;
  height: 35px;

  border-radius: 2px;
  cursor: pointer;
}
.product-variable-color label {
  line-height: 0;
  margin-right: 5px;
  position: relative;
}

.product-variable-color label input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}

.product-variable-color label span {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;

  border-radius: 2px;
  cursor: pointer;
}

.product-variable-color label .product-color-red {
  background: red;
}

.product-variable-color label .product-color-tomato {
  background: tomato;
}

.product-variable-color label .product-color-green {
  background: green;
}

.product-variable-color label .product-color-light-green {
  background: #90ee90;
}

.product-variable-color label .product-color-blue {
  background: #00f;
}

.product-variable-color label .product-color-light-blue {
  background: #add8e6;
}

.product-variable-color label span::after {
  position: absolute;
  color: #fff;
  content: "";
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.product-variable-color label input:checked ~ span::after {
  font-family: FontAwesome;
  content: "\f00c";
}

.modal_share_icons_one {
  padding-top: 15px;
}

.posted_icons_one {
  padding-top: 10px;
}

.posted_icons_one button {
  padding-right: 6px !important;
  transition: all 0.4s;
}

.posted_icons_one button:hover {
  transform: scale(1.2);
}

.modal_share_icons_one h4 {
  font-size: 18px;
  font-weight: 500;
}

.posted_icons_one a svg {
  width: 16px;
  height: 16px;
}

.posted_icons_one a {
  border: 1px solid var(--main-theme-color);
  margin-right: 17px;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}

.plus-minus-input {
  display: flex;
  align-items: center;
}

.plus-minus-input input::-webkit-outer-spin-button,
.plus-minus-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plus-minus-input input[type="number"] {
  -moz-appearance: textfield;
}

.plus-minus-input button {
  background: #000;
  color: #fff;
  font-size: 30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 6px;
  position: relative;
}

.plus-minus-input button i {
  font-size: 13px;
}

.plus-minus-input svg {
  width: 16px;
  height: 18px;
  position: absolute;
  left: 21%;
  top: 4px;
}

/* ---------------------------------
       Shop Page 
-------------------------------------*/

.customs_sel_box {
  width: 140px;
  padding: 2px;
  font-size: 12px;

  height: 15px;
  border: 1px solid;
}

#to_days_area .product_wrappers_one {
  margin: 0 15px 15px 15px;
}

.product_wrappers_one {
  margin-top: 30px;
}

.product_shot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.product_shot_title p {
  font-weight: 400;
  color: #000;
  padding-right: 20px;
}

.product_shot_view ul {
  display: flex;
}

.product_shot_view ul li {
  padding-left: 10px;
}

.product_shot_view ul li:first-child {
  padding-left: 20px;
}

.product_shot_view ul li i {
  font-size: 20px;
}

.pagination {
  padding: 0;
  display: block;
  margin: 40px 0 0;
  text-align: center;
}

.pagination .page-item {
  display: inline-block;
  margin: 0 3px;
}

.pagination .page-item .page-link {
  color: var(--heading-color);
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f5f5f5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.pagination .page-item.active .page-link {
  background-color: var(--main-theme-color);
  color: #ffffff;
  -webkit-box-shadow: 0px 7px 20px 0px #dcdcdc;
  box-shadow: 0px 7px 20px 0px #dcdcdc;
}

.product_shot_view .active {
  color: var(--main-theme-color);
}

/* ---------------------------------
       Shop List Page 
-------------------------------------*/

.product_wrappers_list {
  background: #ffffff;
  padding: 40px 40px;
  box-shadow: 2px 2px 15px 2px #0000000a;
  margin-bottom: 30px;
}

#shop_main_area
  .row:nth-child(2)
  .col-lg-12:first-child
  .product_wrappers_list {
  margin-top: 30px;
}

.product_wrappers_list .content .product_para_top p {
  padding-top: 5px;
}

.product_wrappers_list .content .product_para_top .price {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-theme-color);
  display: block;
  padding-top: 10px;
}

.product_wrappers_list .actions {
  padding-top: 10px;
}

.product_wrappers_list .actions a {
  padding-right: 10px;
  font-size: 18px;
}

.product_wrappers_list .content a {
  margin-top: 20px;
}

/* ---------------------------------
       Shop Sidebar Page 
-------------------------------------*/

.custom_boxed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  position: relative;
  padding-left: 25px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shop_Search {
  position: relative;
}

.shop_Search form input {
  padding: 10px 46px 10px 17px;
  border-radius: 0;
}

.shop_Search form button {
  background: none;
  position: absolute;
  top: 8px;
  right: 15px;
}

.shop_Search form button img {
  width: 20px;
}

.price_filter
  .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
  background: #312620 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  height: 6px;
  margin-bottom: 20px;
  margin-left: auto;
  border-radius: 3px;
}

.price_filter .ui-slider-range {
  background: #312620 none repeat scroll 0 0;
  border-radius: 2px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
  height: 20px;
  width: 20px;
  top: 0;
  margin-top: -7px;
  background: #fff;
  border-radius: 50%;
  margin-left: 0;
  border: 3px solid #312620;
  transition: 0.1s linear;
  display: block;
}

.price_filter .range_count {
  display: flex;
  justify-content: space-between;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:focus {
  outline: none;
  box-shadow: none;
}

.price_slider_amount {
  overflow: hidden;
}

.price_slider_amount > span {
  font-size: 14px;
  font-weight: 500;
  color: #544842;
  text-transform: capitalize;
  margin-right: 5px;
}

.price_slider_amount > input[type="text"] {
  height: auto;
  margin-left: 0;
  text-align: left;
  width: 100px;
  border: none;
  font-size: 14px;
  color: #544842;
  font-weight: 500;
}

.price_slider_amount > input[type="submit"] {
  float: left;
  text-transform: uppercase;
  transition: all 400ms ease-out 0s;
  cursor: pointer;
  width: 98px;
  padding: 11px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.price_filter {
  margin-top: 30px;
}

.price_filter .ui-slider-handle.ui-state-default.ui-corner-all:hover {
  border-color: #ff6000;
}

#sizes_input .custom_boxed {
  padding-left: 25px;
  margin-bottom: 0px;
  margin-right: 20px;
  display: inline-block;
}

.skillbar {
  margin-bottom: 40px !important;
}

/* ---------------------------------
      Product Single Page 
-------------------------------------*/
.slider-nav .nav_img {
  margin-left: 15px;
}

.product_img_two_slider {
  margin-bottom: 30px;
}

.product_img_two_slider img {
  width: 100%;
}

.slider-nav .nav_img:first-child {
  margin-left: 0;
}

#product_single_one .customs_selects {
  padding-top: 30px;
}

#product_single_one .variable-single-item {
  padding-top: 10px;
}

#product_single_one .reviews_rating {
  padding-top: 15px;
}

#product_single_one .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_one .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_one .modal_product_content_one p {
  padding-top: 20px;
}

#product_single_one .links_Product_areas {
  padding-top: 30px;
}

#product_single_one .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_one .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_one .links_Product_areas .theme-btn-one {
  margin-top: 20px;
}

.product_details_tabs {
  padding-top: 60px;
}

.product_details_tabs .nav-tabs {
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
}

.product_details_tabs ul li {
  padding-right: 30px;
}

.product_details_tabs .nav-tabs li a {
  font-size: 18px;
  font-weight: 500;
  color: #afafaf;
}

.product_details_tabs ul li a.active {
  color: var(--main-theme-color);
}

#description .product_description {
  padding-top: 20px;
}

#description .product_description ul {
  padding-left: 55px;
  padding-top: 20px;
}

#description .product_description ul li {
  list-style: disc;
  padding-top: 10px;
}

#description .product_description ul li:first-child {
  padding-top: 0px;
}

#description .product_description ul li:last-child {
  padding-bottom: 10px;
}

#additional .product_additional {
  padding-top: 20px;
}

#additional ul li {
  padding-top: 10px;
  font-weight: 600;
  color: #6b6b6b;
}

#additional ul li:first-child {
  padding-top: 0px;
}

#additional ul li span {
  color: var(--main-theme-color);
  display: block;
  padding-top: 8px;
}

#review .product_reviews {
  padding-top: 20px;
}

#review .product_reviews .media-public ul li {
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
}

#review .product_reviews .media-img {
  padding-right: 20px;
}

#review .product_reviews .media .media-img img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

#review .product_reviews .media-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#review .product_reviews ul .media {
  box-shadow: 0px 0px 15px 6px #96969614;
  padding: 44px 50px;
  margin-top: 30px;
}

#review .product_reviews ul .media:first-child {
  margin-top: 0;
}

#review .product_reviews .media-header .media-name h4 {
  font-size: 20px;
  padding-bottom: 10px;
}

#review .product_reviews .media-header .post-share a {
  font-size: 16px;
  color: #848484;
  padding-left: 18px;
}

#review .product_reviews .media-header .post-share a:hover {
  color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph p {
  padding-top: 5px;
}

#review .product_reviews .media-pragraph .product_review_strat span i {
  color: var(--main-theme-color);
}

#review .product_reviews .media-pragraph {
  padding-top: 5px;
}

/* ---------------------------------
      Product Single Two Page 
-------------------------------------*/

#product_single_two .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 44%;
}

#product_single_two .owl-prev {
  background: black;
  width: 25px;
  height: 35px;
  color: #fff;
}

#product_single_two .owl-next {
  background: black;
  width: 25px;
  height: 35px;
  color: #fff;
}

#product_single_two .owl-prev:hover,
#product_single_two .owl-next:hover {
  background: var(--main-theme-color);
}

#product_single_two .customs_selects {
  padding-top: 30px;
}

#product_single_two .variable-single-item {
  padding-top: 10px;
}

#product_single_two .reviews_rating {
  padding-top: 15px;
}

#product_single_two .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_two .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_two .modal_product_content_one p {
  padding-top: 20px;
}

#product_single_two .links_Product_areas {
  padding-top: 30px;
}

#product_single_two .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_two .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_two .links_Product_areas .theme-btn-one {
  margin-top: 20px;
}

/* ---------------------------------
     About Page 
-------------------------------------*/

.about_top_img img {
  width: 100%;
}

.about_top_left_content h2 {
  text-transform: uppercase;
}

.about_top_left_content h4 {
  line-height: 30px;
  padding-top: 15px;
  font-weight: 400;
}

.about_top_left_content p {
  padding-top: 10px;
}

#about_progressbar {
  background: #f6f6f6;
}

.about_progressbar_content p {
  padding-top: 20px;
}

.skill-progressbar {
  margin-bottom: 35px;
}

.skill-progressbar:last-child {
  margin-bottom: 0;
}

.service_promo_single_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 6px 1px #0000001c;
  padding: 0px 10px;
  height: 100%;
}

.service_prom_content {
  padding-left: 4px;
}

#team_area {
  background-color: #f6f6f6;
}

.team-single {
  background: #fff;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 25px;
}

.team-content {
  position: relative;
  font-size: 16px;
  padding-top: 20px;
}

.team-name {
  font-weight: 700;
  font-size: 20px;
}

.team-img img {
  margin: 0 auto;
}

.team-social {
  width: 100%;
  background: #fff;
  bottom: -22px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
  padding: 10px 0;
}

.team-social li {
  display: inline-block;
  margin-right: 10px;
}

.team-social li:last-child {
  margin-right: 0;
}

.team-social a {
  color: #000;
  transition: all 0.5s ease;
}

.team-social a:hover {
  color: #b19361;
}

.team-single:hover .team-social {
  bottom: -24px;
  opacity: 1;
  visibility: visible;
}

.pos-absolute {
  position: absolute;
}

/* ---------------------------------
     Cart Page 
-------------------------------------*/

/*****************************
* Components - Table
*****************************/

table {
  width: 100%;
}

.table-responsive {
  position: relative;
  z-index: 1;
}

.view {
  color: #b19361;
}

.table_desc {
  border: 1px solid #ededed;
}

.table_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.table-responsive table thead {
  background: var(--main-theme-color);
}

.table_page table thead tr th {
  color: #000000;
  border-bottom: 3px solid var(--main-theme-color);
  border-right: 1px solid #ededed;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
}

.table_page table tbody tr td {
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  text-align: center;
  padding: 8px;
  font-size: 14px;
  word-wrap: wrap;
}

.table-responsive table tbody tr td {
  border-right: 1px solid #ededed;
  font-weight: 400;
  text-align: center;
  min-width: 100px;
}

.order_table table tbody tr td {
  border-left: 2px solid #ededed;
}

.product_stock h6 {
  font-size: 16px;
}

.table_page table tbody tr td.product_tur {
  min-width: 150px;
}

.table_page table tbody tr td.product_tarih {
  min-width: 160px;
}

.table_page table tbody tr td.product_day {
  min-width: 80px;
}

.table_page table tbody tr td.product_name {
  min-width: 80px;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

.coupon_code {
  border: 1px solid #ededed;
  margin-top: 60px;
}

.coupon_code h3,
.coupon_code .h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: var(--main-theme-color);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.coupon_inner {
  padding: 10px 20px 25px;
}

.coupon_inner p {
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner input {
  border: 1px solid #ededed;
  min-height: 38px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #333;
  width: 175px;
}

.coupon_inner a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  border-radius: 3px;
}

.cart_subtotal {
  display: flex;
  justify-content: space-between;
}

.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p span {
  margin-right: 30px;
}

.cart_subtotal .cart_amount {
  font-size: 18px;
  font-weight: 500;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  display: initial;
  font-size: 12px;
}

#cart_area_two .plus-minus-input {
  justify-content: center;
}

#cart_area_two .table_page table tbody tr td.product_quantity input {
  margin: 0 8px;
}

.coupon_inner_two {
  padding-top: 30px;
}

.coupon_inner_two p {
  font-size: 13px;
  margin-bottom: 20px;
}

.coupon_inner_two input {
  border: 1px solid #ededed;
  min-height: 38px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #333;
  width: 175px;
}

.coupon_inner_two a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  border-radius: 3px;
}

.product_quantity input {
  text-align: center;
}

.pro-remove button {
  background: transparent;
}

/* ---------------------------------
    Empty Cart Page 
-------------------------------------*/

.empaty_cart_area {
  text-align: center;
}

.empaty_cart_area img {
  width: 100%;
}

.empaty_cart_area h3 {
  padding-top: 15px;
}

.empaty_cart_area a {
  margin-top: 25px;
}

/* ---------------------------------
       CheckOut Page 
-------------------------------------*/

#checkout_one .form-group label {
  font-size: 16px;
  font-weight: 600;
}

.bg-white {
  background-color: #fff;
}

.checkout-area-bg {
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #00000012;
}

.check-heading {
  border-bottom: 2px solid var(--main-theme-color);
}

.check-heading h3 {
  font-weight: 700;
  padding-bottom: 10px;
}

.check-out-form {
  padding-top: 30px;
}

#checkout-area .form-group {
  margin-bottom: 20px;
}

#checkout-area .form-group label {
  font-size: 16px;
  font-weight: 600;
}

#checkout-area .form-control {
  border: 1px solid var(--main-theme-color);
  background: #fff;
  font-size: 14px;
  border-radius: 0;
}

.order_review {
  background-color: #f7f8fb;
  padding: 30px;
}

.order_table thead th {
  border-bottom-width: 1px;
  font-weight: 600;
  color: #fff;
}

.order_table tbody td {
  border: 0;
}

.order_table tfoot th {
  font-weight: 600;
}

.product-subtotal {
  font-weight: 600;
}

.payment_method {
  margin-bottom: 30px;
  /* display: inline-block; */
  width: 100%;
}

.payment_option .custome-radio {
  margin-bottom: 10px;
}

.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}

.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}

.custome-radio input[type="radio"]:checked + .form-check-label::before {
  border-color: var(--main-theme-color);
}

.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-radio input[type="radio"]:checked + .form-check-label::after {
  opacity: 1;
}

.custome-radio input[type="radio"] + .form-check-label::after {
  content: "";
  background-color: var(--main-theme-color);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}

.payment-text {
  display: none;
  margin-top: 5px;
  margin-bottom: 0;
}

.payment_option .custome-radio:first-child .payment-text {
  display: block;
}

.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.order_review {
  margin-bottom: 40px;
  box-shadow: 0px 0px 10px 0px #00000012;
}

.payment_method {
  padding-top: 30px;
}

.order_table {
  padding-top: 30px;
}

.order_review .table-responsive table thead {
  background: var(--main-theme-color);
  text-align: center;
}

/* ---------------------------------
       CheckOut Two Page 
-------------------------------------*/

.user-actions {
  margin-bottom: 20px;
}

.user-actions h3,
.user-actions .h3 {
  font-size: 13px;
  font-weight: 400;
  background-color: #f7f6f7;
  padding: 15px 10px;
  border-top: 3px solid var(--main-theme-color);
  margin-bottom: 0;
}

.user-actions h3 a,
.user-actions .h3 a {
  color: var(--main-theme-color);
}

.checkout_info {
  border: 1px solid #ededed;
  margin-top: 25px;
  padding: 20px 30px;
}

.checkout_info p {
  margin-bottom: 15px;
  font-size: 14px;
}

.form_group {
  margin-bottom: 20px;
}

.form_group .btn {
  margin-right: 20px;
}

.form_group.group_3 {
  display: flex;
  align-items: center;
}

.checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  height: 45px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

.checkout_coupon .btn {
  margin-left: 20px;
}

.country_option {
  width: 100%;
}

.country_option .list {
  height: 150px;
  overflow-y: scroll;
}

.order-notes {
  margin-bottom: 53px;
}

.default-form-box label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
  display: block;
}

#checkout_two .checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  min-height: 35px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

#checkout_two .checkout_coupon input[type="text"] {
  background: none;
  border: 1px solid #ededed;
  width: 200px;
  max-height: 36px;
  font-size: 12px;
  padding: 0 20px;
  color: #777;
}

.default-form-box .checkbox-default {
  padding-left: 20px;
  margin: 0;
}

#checkout_two .checkout_area_two h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #fff;
  background: var(--main-theme-color);
  font-weight: 500;
}

#checkout_two #checkout_login label span {
  padding-left: 5px;
}

.checkout_form_area form {
  padding-top: 20px;
}

.checkout_form_area form .default-form-box {
  padding-bottom: 20px;
}

.order_table table thead tr th {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.order_table table tbody tr td {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr th {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.order_table table tfoot tr td {
  min-width: 50%;
  text-align: left;
  padding: 15px 30px;
  border-bottom: 1px solid #ddd;
}

.input-radio span {
  font-weight: 500;
  padding-right: 10px;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.checkbox-default-more-text em {
  margin-top: 16px;
  display: block;
}

#methodCod p {
  padding-bottom: 20px;
}

.checkout_area_two .table-responsive table thead {
  background: #000;
}

.AkayaTelivigala {
  font-family: "AkayaTelivigala", sans-serif;
}
/* ---------------------------------
     Order Tracking 
-------------------------------------*/

.order_tracking_wrapper {
  box-shadow: 0px 0px 7px 3px #0000002b;
  padding: 20px 30px;
}

.order_tracking_wrapper p {
  text-align: center;
}

.order_tracking_wrapper h4 {
  padding-bottom: 10px;
  text-align: center;
}

.order_tracking_wrapper form {
  padding-top: 20px;
}

.order_track_button {
  text-align: center;
}

/* ---------------------------------
     Error Page 
-------------------------------------*/

.erorr_wrapper {
  text-align: center;
}

.erorr_wrapper h1 {
  font-weight: 200;
  font-size: 200px;
  text-shadow: 2px 8px 5px #f7983747;
  font-style: italic;
  line-height: 165px;
}

.erorr_wrapper h3 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.erorr_wrapper form input {
  border-radius: 0px;
}

.erorr_wrapper form button {
  background: #000;
  color: #fff;
  padding: 1px 20px;
}

.erorr_wrapper a {
  margin-top: 30px;
}

/* ---------------------------------
     Compare Page 
-------------------------------------*/

.compare-table .table tbody tr td.first-column {
  min-width: 180px;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 305px;
  vertical-align: bottom;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category:hover,
.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #b19361;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  color: #333;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 15px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  transition: 0.3s;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.pro-stock {
  font-size: 15px;
  font-weight: 600;
}

.compare-table
  .table
  tbody
  tr
  td.pro-addtocart
  .add-to-cart
  .pro-remove
  button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.compare-table table {
  margin-bottom: 0;
}

/* ---------------------------------
     My Account Page 
-------------------------------------*/

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.dashboard_tab_button {
  margin-bottom: 50px;
}

.dashboard_tab_button li a {
  border: 1px solid #e8e8e8;
  border-bottom: none;
  color: #212121;
  font-weight: 500;
  font-size: 15px;
  display: block;
  padding: 18px 15px;
  text-transform: uppercase;
}

.dashboard_tab_button li a:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.dashboard_tab_button li a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.dashboard_tab_button li a.active,
.dashboard_tab_button li a:hover {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  color: #fff;
}

.dashboard_content h4,
.dashboard_content .h4 {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #212121;
  border-bottom: 1px dashed #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 500;
}

.profils_details_vendor h4 {
  border-bottom: none;
}

.profile_right h4 {
  font-weight: 600 !important;
}

.dashboard_content p a {
  color: #b19361;
  font-weight: 500;
}

.billing_address h5 {
  padding-bottom: 10px;
}

.input-radio span {
  font-weight: 500;
  padding-right: 10px;
}

.input-radio span input[type="radio"],
.account_login_form form span input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  position: relative;
  top: 2px;
}

.checkbox-default-more-text em {
  margin-top: 16px;
  display: block;
}

.account_details_form .default-form-box {
  margin-bottom: 20px;
}

.account_details_form .input-radio {
  margin-top: 20px;
}

.account_details_form .example {
  font-size: 14px;
  display: block;
}

.myaccount-content .title {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  color: #212121;
  border-bottom: 1px dashed #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 500;
}

.myaccount-content {
  border: 1px solid #e8e8e8;
  padding: 30px;
}

.myaccount-content table {
  overflow: hidden;
}

.billing_address p {
  padding-top: 10px;
}

.billing_address a {
  margin-top: 20px;
}

.img_profiles {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--main-theme-color);
  overflow: hidden;
}

.fixed_icon input {
  opacity: 0;
  position: absolute;
}

/* ---------------------------------
   Login Page 
-------------------------------------*/

.account_form {
  box-shadow: 0px 0px 7px 5px #0000000a;
  padding: 30px 40px;
  background: #fff;
}

.account_form h3 {
  text-align: center;
}

.account_form form {
  padding-top: 30px;
}

.account_form form .default-form-box {
  margin-bottom: 16px;
}

.login_submit {
  text-align: center;
}

.remember_area {
  padding-top: 20px;
  padding-bottom: 10px;
}

/* ---------------------------------
   Faqs Page 
-------------------------------------*/

.faqs_area_wrapper .card_items_area {
  margin-bottom: 20px;
}

.faqs_area_wrapper .collapse {
  background: #f7f7f7;
  padding: 20px 20px;
}

.faqs_area_wrapper .collapse .card-body {
  padding: 0px;
}

.faqs_area_wrapper .card-header {
  width: 100%;
  text-align: left;
  font-size: 18px;
  padding: 0;
}

.faqs_area_wrapper .card-header a h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

/* ---------------------------------
   Privacy Policy Page 
-------------------------------------*/

.privacy_policy_text {
  padding-top: 30px;
}

.privacy_policy_text p {
  padding-top: 20px;
}

/* ---------------------------------
   Coming Soon 
-------------------------------------*/

#coming_soon_area {
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  /* background-image: url("../img/common/coming-soon.jpg"); */
  position: relative;
  display: flex;
  align-items: center;
}

#coming_soon_area::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000054;
}

.coming_soon_content {
  text-align: center;
  z-index: 999;
  position: relative;
}

.coming_soon_title {
  padding-top: 30px;
}

.coming_soon_title h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.coming_soon_title p {
  color: #fff;
  padding-top: 10px;
}

.coming_soon_time {
  padding-top: 40px;
}

.coming_soon_newsletter {
  padding-top: 40px;
}

.coming_soon_newsletter h3 {
  color: #fff;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
}

.coming_soon_newsletter form input {
  min-height: 50px;
}

/* -------------------------
  Blog Page Area Start 
---------------------------*/

.blog_one_item {
  overflow: hidden;
}

.blog_one_img {
  overflow: hidden;
}

.blog_one_item a img {
  width: 100%;
  overflow: hidden;
}

.blog_text {
  padding-top: 15px;
}

.blog_text .date_area a {
  font-size: 16px;
  color: var(--main-theme-color);
  font-weight: 600;
  text-decoration: underline;
}

.blog_text .heading {
  padding-top: 15px;
  line-height: 32px;
}

.blog_text .para {
  padding-top: 10px;
}

.blog_text .button {
  display: block;
  font-size: 16px;
  color: var(--heading-color);
  font-weight: 600;
  border: 1px solid;
  padding: 6px 28px;
  width: 175px;
  margin-top: 17px;
}

.blog_text .button:hover {
  color: var(--main-theme-color);
}

.blog_text .button i {
  padding-left: 10px;
}

#blog_grid_area_one .blog_one_item {
  margin-bottom: 30px;
}

/* -------------------------
  Blog List Page Area
---------------------------*/

#blog_list_area .blog_list_item {
  margin-bottom: 30px;
}

/* -------------------------
  Blog single Page Area
---------------------------*/

.blog_single_img {
  overflow: hidden;
}

.blog_single_img img {
  width: 100%;
}

.blog_single_widget {
  padding-top: 30px;
}

.blog_single_first_Widget {
  padding-top: 15px;
}

.blog_single_first_Widget h2 {
  font-size: 28px;
  font-weight: 600;
}

.blog_single_first_Widget p {
  padding-top: 15px;
}

.blog_single_first_Widget blockquote {
  background: #eaeaea;
  margin-top: 35px;
  padding: 20px 40px;
  font-size: 20px;
  font-style: italic;
}

.blog_details_center_img {
  padding-top: 20px;
}

.blog_single_secend_widget {
  padding-top: 30px;
}

.blog_single_secend_widget h2 {
  font-size: 28px;
  font-weight: 600;
}

.blog_single_secend_widget p {
  padding-top: 15px;
}

.single_categoris_bottom {
  padding-top: 30px;
}

.single_categoris_bottom ul li {
  display: inline-flex;
}

.single_categoris_bottom ul li a {
  color: #636161;
  border: 1px solid #dcd9d9;
  padding: 7px 10px;
  font-size: 14px;
}

.single_categoris_bottom ul li a:hover {
  color: var(--main-theme-color);
  border: 1px solid var(--main-theme-color);
}

.post_author .author_img {
  float: left;
  padding-right: 15px;
}

.post_author .author_info .author_name a {
  font-size: 18px;
  font-weight: 600;
}

.post_author .author_info p {
  padding-top: 10px;
}

.post_author {
  margin-top: 30px;
}

.related_blogs {
  padding-top: 30px;
}

.single_comment_area {
  padding-top: 50px;
}

.content_title h3 {
  font-size: 24px;
}

.comment_user img {
  height: auto;
  max-width: 80px;
}

.comment_content {
  padding-left: 20px;
}

.comment_content .meta_data h6 {
  font-size: 16px;
}

.comment-time {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-style: italic;
}

.comment_content p:last-child {
  margin-bottom: 0;
}

.comment_info {
  padding-bottom: 20px;
}

.comment_info .children {
  margin-top: 20px;
  padding-top: 20px;
  margin-left: 60px;
}

.comment_list {
  padding-top: 30px;
}

.comment_replay_box {
  padding-top: 20px;
}

.comment_replay_box form {
  padding-top: 20px;
}

/* --Sidebar-- */

.shop-sidebar-wrap h3 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.2px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
}

.shop-sidebar-wrap h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #ff7004;
}

.left-sidebar button {
  position: absolute;
  width: 50px;
  left: auto;
  right: 0;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  border-radius: 0;
  border-color: #ebebeb;
  background-color: #212121;
  color: #fff;
  padding: 0;
  z-index: 99999;
}

.search-widget form input {
  min-height: 50px;
}

.left-sidebar .category-post li {
  margin-bottom: 10px;
  display: block;
}

.left-sidebar .category-post li a {
  line-height: 24px;
  position: relative;
  display: block;
}

.left-sidebar .category-post li a span {
  float: right;
}

.left-sidebar .recent-post-widget .recent-single-post {
  margin: 0 0 30px;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side {
  overflow: hidden;
}

.left-sidebar .recent-post-widget .recent-single-post .thumb-side img {
  max-width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side {
  margin: 0 0 0 18px;
  align-self: center;
  align-items: center;
  line-height: 24px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side h5 {
  font-size: 16px;
}

.left-sidebar .recent-post-widget .recent-single-post .media-side span {
  font-size: 14px;
  padding-top: 8px;
  display: block;
}

.shop-sidebar-wrap .sidebar-widget-tag li {
  line-height: 1;
  float: left;
  list-style: none;
}

.shop-sidebar-wrap .sidebar-widget-tag li:first-child a {
  margin-left: 0;
}

.shop-sidebar-wrap .sidebar-widget-tag li a {
  display: block;
  float: left;
  padding: 0 20px;
  line-height: 33px;
  font-weight: 400;
  border: 1px solid #ebebeb;
  border-radius: 30px;
  background: #fff;
  margin: 4px;
  text-transform: capitalize;
  color: #474747;
}

.shop-sidebar-wrap .sidebar-widget-tag li a:hover {
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
  color: #fff;
}

.flicker-widget li {
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  overflow: hidden;
}

.flicker-widget img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  object-position: center;
}

.single_center_img {
  overflow: hidden;
}

.single_center_img img {
  width: 100%;
}

.related_blogs .blog_one_item {
  margin-bottom: 30px;
}

.follwos_icons ul li {
  display: inline-flex;
  padding-left: 20px;
}

.follwos_icons ul li:first-child {
  padding-left: 0px;
}

.follwos_icons p {
  padding-top: 20px;
}

/**=====================
    invoice pages start
==========================**/
.invoice-wrapper {
  background-color: white;
  -webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
  box-shadow: #e6e6e6 0px 0px 14px 3px;
}

.invoice-wrapper h2 {
  font-size: 30px;
}

.invoice-wrapper h4 {
  color: #646464;
  font-size: 18px;
  font-weight: 400;
}

.invoice-wrapper .invoice-detail h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 600;
}

.invoice-wrapper .invoice-detail h6 {
  margin-bottom: 0;
  font-size: 16px;
  color: #6d6d6d;
}

.invoice-wrapper .invoice-body {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .invoice-body .table th,
.invoice-wrapper .invoice-body .table td {
  border-top: none;
}

.invoice-wrapper .invoice-body .table {
  font-size: 16px;
}

.invoice-wrapper .invoice-body .table td {
  color: #646464;
}

.invoice-wrapper .invoice-body .table tbody td,
.invoice-wrapper .invoice-body .table tbody th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.invoice-wrapper .invoice-body .table tbody th {
  font-weight: normal;
  color: #646464;
}

.invoice-wrapper .invoice-body .table tbody tr:first-child td,
.invoice-wrapper .invoice-body .table tbody tr:first-child th {
  padding-top: 22px;
}

.invoice-wrapper .invoice-body .table tbody tr:last-child td,
.invoice-wrapper .invoice-body .table tbody tr:last-child th {
  padding-bottom: 22px;
}

.invoice-wrapper .invoice-body .table tfoot {
  border-top: 1px solid #ddd;
}

.invoice-wrapper .invoice-body .table tfoot td {
  padding-top: 15px;
}

.invoice-wrapper .invoice-footer {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper .authorise-sign h6 {
  margin-bottom: 0;
  font-size: 18px;
  color: black;
  font-weight: bold;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  line-height: 1;
}

.theme-invoice-1 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  position: relative;
}

.theme-invoice-1 .invoice-header h4 {
  font-size: 16px;
  color: #646464;
  margin-bottom: 5px;
}

.theme-invoice-1 .invoice-header .upper-icon {
  position: absolute;
  width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  top: 0;
  right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--theme-deafult);
}

.theme-invoice-1 .invoice-header .upper-icon img {
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
  margin-top: 50px;
}

.theme-invoice-1 .invoice-header .header-content h2 {
  font-size: 30px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
  color: var(--theme-deafult);
  text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
  margin-bottom: 0;
  margin-left: 4px;
}

.theme-invoice-4 .invoice-header {
  padding: calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.theme-invoice-4 .invoice-header .background-invoice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.theme-invoice-4 .invoice-wrapper .invoice-body {
  padding: 0;
  z-index: 1;
  position: relative;
}

.theme-invoice-4 .top-sec {
  padding: 0 calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .date-detail li h4 {
  margin-bottom: 0;
}

.theme-invoice-4 .date-detail li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.theme-invoice-4 .date-detail li span {
  font-size: 18px;
  text-transform: capitalize;
  color: var(--main-theme-color);
  margin-right: 8px;
}

.theme-invoice-4 .title-sec {
  padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  position: relative;
}

.theme-invoice-4 .title-sec .title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.theme-invoice-4 .table-sec {
  padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead {
  background-color: black;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table thead th {
  border-bottom: 0 !important;
  padding: 18px;
  color: white;
}

.invoice-wrapper .invoice-body .table thead th {
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 16px;
  border-color: var(--main-theme-color) !important;
  border-bottom: 1px solid;
}

.theme-invoice-4 .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: white;
  color: #646464;
}

.theme-invoice-4 .table-striped > tbody > tr:nth-of-type(even) {
  --bs-table-accent-bg: #0000000d;
  color: #212529;
}

.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody th,
.theme-invoice-4 .invoice-wrapper .invoice-body .table tbody td {
  padding: 18px 18px !important;
  font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer {
  background-color: var(--main-theme-color);
  display: inline-block;
  padding: 10px 10px 10px 10px;
  color: white;
  margin-top: 30px;
  font-size: 18px;
}

.theme-invoice-4 .table-sec .table-footer span:first-child {
  margin-right: 20px;
}

.theme-invoice-4 .table-sec .table-footer span:nth-child(2) {
  font-weight: 600;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)))
    calc(15px + (65 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
  position: relative;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer .design-shape {
  position: absolute;
  top: -34px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li i {
  margin-right: 30px;
  color: var(--main-theme-color);
  font-size: 20px;
}

.theme-invoice-4 .invoice-wrapper .invoice-footer ul li h4 {
  line-height: 24px;
  font-size: 16px;
}

/* --Invoice Page Two-- */
.theme-invoice-1 .invoice-header {
  padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: #f8f8f8;
}

.theme-invoice-1 .invoice-header .upper-icon {
  position: absolute;
  width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
  top: 0;
  right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--main-theme-color);
}

.theme-invoice-1 .invoice-header .upper-icon img {
  width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.theme-invoice-1 .invoice-header .header-content {
  margin-top: 50px;
}

.theme-invoice-1 .invoice-header .detail-bottom {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dddddd;
}

.theme-invoice-1 .invoice-header .detail-bottom ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li span {
  color: var(--main-theme-color);
  text-transform: capitalize;
}

.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
  margin-bottom: 0;
  margin-left: 4px;
}

.invoice-wrapper .authorise-sign {
  position: absolute;
  bottom: calc(13px + (40 - 13) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
}

/* -------------------------
  Contact Page Area
---------------------------*/

.contact_info {
  padding-bottom: 50px;
}

.contact_info h3 {
  padding-bottom: 15px;
}

.left_side_contact ul li {
  padding-bottom: 30px;
}

.left_side_contact .address_location .contact_widget {
  display: flex;
}

.address_location i {
  padding-top: 4px;
  font-size: 18px;
}

.left_side_contact .address_location p {
  padding-left: 20px;
  color: #0a0d14;
}

.contact_widget a {
  display: block;
  padding-left: 20px;
  padding-bottom: 10px;
}

.contact_form_one form .form-group {
  margin-bottom: 30px;
}

.contact_form_one h3 {
  padding-bottom: 30px;
}

.map_area {
  margin-top: 50px;
}

.map_area iframe {
  width: 100%;
  border: none;
}

.contact_info_wrapper .map_area {
  margin-top: 0px;
}

#login_area .active {
  color: var(--main-theme-color);
}

/* -------------------------
  Product Hover Page 
---------------------------*/
#elce_weekly_deal .product_item_two {
  margin: 30px 15px 0 0;
}

.product_item_two {
  margin-top: 30px;
}

.product_hover_heading {
  text-align: center;
}

/* --Product Hover TWo-- */
.product_item_two .product_item_inner {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}

.product_item_two.product_item_inner img {
  width: 100%;
}

.product_item_two .product_button {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px rgb(0 0 0 / 8%);
  opacity: 0;
  bottom: -50px;
  left: 50%;
  visibility: hidden;
  position: absolute;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  border: 1px solid #eeeeee;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: table;
  text-align: center;
}

.product_item_two .product_button a {
  border-right: 1px solid #eeeeee;
  font-size: 18px;
  padding: 15px 20px;
  display: table-cell;
}

.product_button a:last-child {
  border: none;
}

.product_item_two .product_detail {
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
}

.product_item_two:hover .product_button {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.product_item_two .product_detail .product_title a {
  font-size: 16px;
}

.product_item_two .product_detail .item_price {
  padding-top: 5px;
  font-weight: 600;
}

/* --Product Hover Three-- */
#hot_Product_area .product_box {
  margin-top: 30px;
}

.product_box {
  margin-bottom: 0px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.product_img {
  position: relative;
  overflow: hidden;
}

.product_img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.product_img img {
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.product_action_box {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all 0.5s ease-in-out;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pr_action_btn {
  display: table;
  margin: 0 auto;
}

.product_action_box li:nth-child(1) {
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.product_action_box li {
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0;
}

.pr_action_btn li {
  position: relative;
  margin: 0px 5px;
  display: inline-block;
}

.product_box .pr_action_btn li a {
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.pr_action_btn li a {
  background-color: #fff;
  font-size: 0;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  width: 37px;
  height: 37px;
  padding: 0;
  display: block;
  text-align: center;
}

.pr_action_btn li a i {
  vertical-align: middle;
  line-height: 37px;
  font-size: 18px;
}

.product_box .product_info {
  position: relative;
}

.product_info {
  padding: 15px;
}

.product .product_title,
.product_box .product_title,
.product_wrap .product_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  padding-bottom: 6px;
}

.product_price {
  margin-bottom: 5px;
}

.product_rate {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
  color: #f6bc3e;
}

.rating_num {
  font-size: 14px;
  margin-top: 6px;
  vertical-align: middle;
  display: inline-block;
}

.product_box .add-to-cart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  background-color: #fff;
  padding: 10px 15px 15px 15px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}

.btn-fill-out {
  background-color: transparent;
  border: 1px solid #ff324d;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.product:hover .product_img:before,
.product_box:hover .product_img:before {
  opacity: 1;
}

.product_box:hover .add-to-cart {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product:hover .product_action_box li,
.product_box:hover .product_action_box li,
.product_wrap:hover .product_action_box li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.product_price del {
  font-size: 14px;
  margin-right: 5px;
  margin-left: 3px;
}

.product_price .price {
  color: #ff324d;
  font-weight: 600;
}

/*--------------------------------
         LookBook Page
-----------------------------------*/
.lookbook .lookbook-block {
  position: relative;
}

.lookbook .lookbook-block .lookbook-dot {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 29px;
  height: 29px;
  line-height: 29px;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 50%;
  left: 12%;
  background-color: #ffffff;
}

.lookbook .lookbook-block .lookbook-dot:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  zoom: 1;
  background-color: transparent\9;
  z-index: -1;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.lookbook .lookbook-block .lookbook-dot span {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
  visibility: hidden;
  top: -98px;
  left: 150%;
  position: absolute;
  width: 130px;
  background-color: #ffffff;
  -webkit-box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
  box-shadow: -3px -3px 13px rgb(48 54 61 / 10%);
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid #ffffff;
  border-bottom: 8px solid transparent;
  top: 41%;
  left: -8px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
  margin-top: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  padding: 5px;
  text-align: center;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
  font-weight: 700;
  font-size: 16px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
  margin-bottom: 0;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 14px;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
  text-transform: uppercase;
  color: var(--main-theme-color);
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
  top: 70%;
  left: 27%;
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
  top: 36%;
  left: 35%;
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
  top: 66%;
  left: 55%;
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
  top: 23%;
  left: 38%;
}

.lookbook .lookbook-block .lookbook-dot:hover {
  background-color: var(--main-theme-color);
  -webkit-box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
  box-shadow: 0 0 0 3px rgb(255 255 255 / 50%);
  z-index: 10;
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
  visibility: visible;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
  transform: scale(1.3);
}

.lookbook .lookbook-block .lookbook-dot:hover span {
  color: #ffffff;
}

.img-width img {
  width: 100%;
}

.padd-bottom {
  padding-bottom: 30px;
}

/*--------------------------------
        Account Info Edit Page
-----------------------------------*/
.account_thumd {
  text-align: center;
  position: relative;
}

.account_thumd img {
  border-radius: 50%;
}

.account_thumd h4 {
  padding-top: 25px;
  padding-bottom: 5px;
}

.account_thumb_img {
  position: relative;
}

.fixed_icon {
  position: absolute;
  background: #ffffff;
  bottom: 6%;
  right: 10%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 18px;
  transform: translate(-4px, -7px);
  box-shadow: 4px 6px 13px 0px #00000030;
}

.account_setting {
}

.account_setting_heading {
  padding-bottom: 20px;
}

.account_setting_heading h2 {
  padding-bottom: 5px;
}

#account_info_form .form-control {
  margin-bottom: 5px;
  font-size: 14px;
}

#account_info_form .form-group {
  margin-bottom: 10px;
}

#account_info_form .input-radio {
  padding-bottom: 20px;
}

/*--------------------------------
    Order Completed Page
-----------------------------------*/
.order_complete i {
  color: #ff324d;
  font-size: 80px;
  margin-bottom: 20px;
}

.order_complete_heading {
  padding-bottom: 30px;
}

.order_complete p {
  padding-bottom: 30px;
}

/*--------------------------------
   Vandor Dashboard Page
-----------------------------------*/
.vendor_top_box {
  text-align: center;
  border: 1px solid #f79837;
  padding: 20px 0;
  box-shadow: 3px 5px 7px 1px #00000014;
  transition: 0.4s all ease-in-out;
}

.vendor_top_box:hover {
  box-shadow: none;
}

.vendor_top_box h2 {
  font-weight: 600;
  color: var(--main-theme-color);
}

.vendor_top_box h4 {
  margin-bottom: 0;
  font-weight: 400;
  padding-top: 5px;
}

.mychart_area {
  padding-top: 30px;
}

.pending_table {
  background: #fff;
  box-shadow: 3px 3px 6px 3px #00000008;
  overflow: hidden;
}

.pending_table thead tr th {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.pending_table tbody tr {
  /* border-bottom: 1px solid #eee; */
  margin-bottom: 10px;
}

.pending_table tbody tr td {
  vertical-align: middle;
  text-align: center;
}

.btn_right_table {
  text-align: right;
  margin-top: 30px;
}

/* --Vendor Profile-- */
.profils_details_vendor {
  display: flex;
}

.profils_details_vendor .profile_left {
  width: 150px;
  margin-right: 15px;
}

.profils_details_vendor .profile_left {
  display: flex;
  align-items: center;
}

.vendors_profiles ul li h4 {
  font-size: 16px;
  color: #787878;
  font-weight: 400;
}

.vendors_profiles .btn_left_table {
  padding-top: 10px;
}

/* --Setting Area-- */
.setings_boxed {
  box-shadow: 0px 0px 3px 2px #00000014;
  padding: 20px 20px;
  margin-bottom: 30px;
}

.setings_boxed h3 {
  font-size: 18px;
  padding-bottom: 10px;
}

.setings_boxed form input {
  margin-right: 10px;
}

/*--------------------------------
        Add Product Page
-----------------------------------*/
.add_product_form .fotm-group {
  margin-bottom: 20px;
}

.add_product_form .fotm-group select {
  display: block;
  width: 100%;
}

.add_product_wrapper h3 {
  padding-bottom: 30px;
}

.add_product_form {
  box-shadow: 0px 2px 8px 4px #0000000f;
  background: #fff;
  padding: 30px 25px;
}

.image-input {
  text-align: center;
}

.image-input input {
  display: none;
}

.image-input label {
  display: block;
  color: #fff;
  background: #000;
  padding: 0.3rem 0.6rem;
  font-size: 17px;
  cursor: pointer;
  width: 26%;
  margin: 21px auto 33px auto;
}

.image-input label i {
  font-size: 125%;
  margin-right: 0.3rem;
}

.image-input label:hover i {
  animation: shake 0.35s;
}

.image-input img {
  max-width: 200px;
  max-height: 200px;
  border: 2px solid #ffc107;
  margin: 0 auto;
}

.back_to_area a {
  font-size: 14px;
  color: var(--main-theme-color);
}

.back_to_area {
  padding-bottom: 40px;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/*--------------------------------
            Preloader
-----------------------------------*/
#preloader {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999999;
}

#status {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

/*--------------------------------
            Offer Modal 
-----------------------------------*/
.newsleetre_modal.modal-body .modal-bg {
  background-color: #e5e5e7;
  padding: 0px;
}

.newsleetre_modal.modal-body {
  padding: 0px;
}

.newsleetre_modal.modal-body .offer_modal_img {
  text-align: right;
}

.newsleetre_modal.modal-body .offer_modal_left {
  padding-left: 80px;
  padding-top: 30px;
}

.newsleetre_modal .modal-body .offer_modal_left img {
  width: 200px;
  height: 60px;
}

.newsleetre_modal .modal-body .offer_modal_left h3 {
  font-size: 18px;
  font-weight: 700;
  padding-top: 25px;
}

.newsleetre_modal .modal-body .offer_modal_left p {
  font-size: 14px;
  line-height: 24px;
  padding-top: 15px;
}

.newsleetre_modal .modal-body .offer_modal_left form input {
  border: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.newsleetre_modal.modal-body .offer_modal_left form label {
  font-size: 14px;
}

.newsleetre_modal .modal-body .offer_modal_left form button {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.newsleetre_modal .modal-body .offer_modal_left form .check_boxed_modal {
  padding-top: 10px;
  padding-left: 20px;
}

.newsleetre_modal .modal-body .offer_modal_left form {
  padding-top: 30px;
}

.newsleetre_modal .modal-body .close {
  padding-right: 10px;
  padding-top: 13px;
  position: absolute;
  right: 25px;
  top: 0;
  font-size: 30px;
  cursor: pointer;
  z-index: 999;
}

/*--------------------------------
        Cookies area
-----------------------------------*/
.cookie-bar.show {
  bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cookie-bar p {
  color: #fff;
}

.cookie-bar a {
  margin-left: 20px;
}

.cookie-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  /* bottom: -70px; */
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: var(--main-theme-color);
  padding: 10px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 99;
  border-top: 1px solid #fef5ef96;
}
.email-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  /* bottom: -70px; */
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  z-index: 99;
  border-top: 1px solid #fef5ef96;
}
/* ---Email Templates-- */
.tables_area {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  display: block;
}

.box_table {
  box-shadow: 0px 0px 11px 5px #0000000f;
}

.tables_area ul {
  margin: 0;
  padding: 0;
}

.tables_area li {
  display: inline-block;
  text-decoration: unset;
}

.tables_area a {
  text-decoration: none;
}

.tables_area p {
  margin: 15px 0;
}

.tables_area h5 {
  color: #444;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
}

.tables_area .text-center {
  text-align: center;
}

.tables_area .main-bg-light {
  background-color: #fafafa;
}

.tables_area .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.tables_area table.top-0 {
  margin-top: 0;
}

.tables_area table.order-detail,
.tables_area .order-detail th,
.tables_area .order-detail td {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.tables_area .order-detail th {
  font-size: 16px;
  padding: 15px;
  text-align: center;
}

.tables_area .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ---Email Template One--- */
.email_template_one {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Open Sans", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_template_one ul {
  margin: 0;
  padding: 0;
}

.email_template_one li {
  display: inline-block;
  text-decoration: unset;
}

.email_template_one a {
  text-decoration: none;
}

.email_template_one h5 {
  margin: 10px;
  color: #777;
}

.email_template_one .text-center {
  text-align: center;
}

.email_template_one .main-bg-light {
  background-color: #fafafa;
}

.email_template_one .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: capitalize;
  display: inline-block;
  line-height: 1;
}

.email_template_one .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_template_one .main-logo {
  width: 180px;
  padding: 10px 20px;
}

.email_template_one .product-box .product {
  /*border:1px solid #ddd;*/
  text-align: center;
  position: relative;
  margin: 0 15px;
}

.email_template_one .product-info {
  margin-top: 15px;
}

.email_template_one .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_template_one .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_template_one .add-with-banner > td {
  padding: 0 15px;
}

.email_template_one .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ---Email Template Two---- */
.email_two_area {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Lato", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_two_area ul {
  margin: 0;
  padding: 0;
}

.email_two_area li {
  display: inline-block;
  text-decoration: unset;
}

.email_two_area a {
  text-decoration: none;
}

.email_two_area h5 {
  margin: 10px;
  color: #777;
}

.email_two_area .text-center {
  text-align: center;
}

.email_two_area .main-bg-light {
  background-color: #fafafa;
}

.email_two_area .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.email_two_area .menu {
  width: 100%;
}

.email_two_area .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_two_area .main-logo {
  width: 180px;
  padding: 10px 20px;
  margin-bottom: -5px;
}

.email_two_area .product-box .product {
  text-align: center;
  position: relative;
}

.email_two_area .product-info {
  margin-top: 15px;
}

.email_two_area .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_two_area .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_two_area .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

/* ----Email Template Three---- */
.email_area_three {
  text-align: center;
  margin: 0 auto;
  width: 650px;
  font-family: "Lato", sans-serif;
  background-color: #e2e2e2;
  display: block;
}

.email_area_three ul {
  margin: 0;
  padding: 0;
}

.email_area_three li {
  display: inline-block;
  text-decoration: unset;
}

.email_area_three a {
  text-decoration: none;
}

.email_area_three h5 {
  margin: 10px;
  color: #777;
}

.email_area_three .text-center {
  text-align: center;
}

.email_area_three .main-bg-light {
  background-color: #fafafa;
}

.email_area_three .title {
  color: #444444;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
}

.email_area_three .menu li a {
  text-transform: capitalize;
  color: #444;
  font-size: 16px;
  margin-right: 15px;
}

.email_area_three .main-logo {
  width: 180px;
  padding: 10px 20px;
  margin-bottom: -5px;
}

.email_area_three .product-box .product {
  text-align: center;
  position: relative;
}

.email_area_three .product-info {
  margin-top: 15px;
}

.email_area_three .product-info h6 {
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: #777;
  margin-top: 0;
}

.email_area_three .product-info h4 {
  font-size: 16px;
  color: #444;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 5px;
  line-height: 1;
}

.email_area_three .footer-social-icon tr td img {
  margin-left: 5px;
  margin-right: 5px;
}

.back_btn {
  margin-bottom: 60px;
}

.back_btn a {
  font-size: 16px;
  color: var(--main-theme-color);
  font-weight: 500;
}

.back_btn a i {
  margin-right: 10px;
}

.contact_info_wrapper {
  background: #fff;
  box-shadow: 0 0 6px 5px rgb(0 50 85 / 6%);
  padding: 30px 25px;
  height: 100%;
}

.back_btn_emial {
  padding-top: 36px;
  padding-bottom: 30px;
  background: #fff;
  text-align: left;
}

.side_card ul li {
  background: #f7f7f7;
  margin-bottom: 30px;
  padding: 24px 20px;
  box-shadow: -3px 0px 0px 0px;
}

#rating-star-furniture {
  display: inline-block;
  padding: 0px 10px 0 0px !important;
}

.after_login {
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
/* 
.after_login img {
    width: 22px;
    margin-right: 6px;
    height: 22px;
    border-radius: 50%;
    background: #f79837;
} */

.after_login i {
  padding-left: 6px;
}

.custom_dropdown {
  display: block;
  height: auto;
  z-index: 999999;
  position: absolute;
  padding: 14px 17px;
  background: #fff;
  box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);
  transform: perspective(600px) rotateX(-90deg);
  transform-origin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  top: 33px;
  text-align: left;
  right: 0;
  width: max-content;
}

.after_login:hover .custom_dropdown {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.custom_dropdown li {
  padding-left: 0;
  padding-top: 7px;
}

.custom_dropdown li > a {
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 22px;

  text-transform: capitalize;
  color: #777;
  position: relative;
  transition: all 0.5s ease;
}

.custom_dropdown li:first-child {
  padding-left: 0;
  padding-top: 0;
}

.heading_payment label {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;
}

.payment_body {
  padding: 6px 0px 9px 23px;
}

.payment_body img {
  cursor: pointer;
}

.payment_area_wrappers {
  margin-bottom: 10px;
}

.btn-main-search {
  color: #fff;
  background: var(--main-theme-color);
}

.go-top {
  position: fixed;
  cursor: pointer;
  right: 25px;
  color: #ffffff;
  background-color: var(--main-theme-color);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  transition: 0.9s;
  border-radius: 50%;
  top: 88%;
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}

.go-top i {
  position: absolute;
  top: 43%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 50%;
}

.go-top:hover,
.go-top:focus {
  color: #ffffff;
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: white;
}

#related_product .product_wrappers_one {
  margin-top: 0;
}

#vendor_area table {
  border: 1px solid #e9ecef;
}

/* ----------------------------
        Grocery Page
-------------------------------*/

/* --Banner-- */
#grocery_banner {
  background-image: url("../img/grocery/banner/grocery_banner_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.banner_last_img {
  padding-bottom: 25px;
}

.grocery_heading h2 {
  font-weight: 600;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 20px;
  position: relative;
}

.grocery_heading {
  margin-bottom: 30px;
}

.grocery_heading h2::after {
  content: "";
  position: absolute;
  width: 300px;
  height: 3px;
  background-color: var(--main-theme-color);
  bottom: 0;
  left: 0;
}

.grocery_small_item {
  border: 1px solid #d4d0d0;
  text-align: center;
  padding: 25px 13px;
  transition: 0.4s all ease-in-out;
}

.grocery_small_item:hover {
  background: #e2e2e2;
}

.grocery_small_item h4 {
  font-size: 18px;
  padding-top: 10px;
}

.grocery_small_item p {
  padding-top: 10px;
  font-weight: 600;
}

.slider_button_style .owl-nav {
  top: -115px;
  position: absolute;
  right: 0;
}

#grocery_category_top .slick-slide img {
  display: inherit;
}

#grocery_featured_area .slick-slide img {
  display: initial;
}

.discount_item a img {
  width: 100%;
}

.discount_item {
  padding-bottom: 20px;
}

.sp_product_item {
  background: #f2f4f7;
  border-radius: 6px;
  text-align: center;
  border: 4px solid #fff;
}

.sp_product_thumb {
  position: relative;
  padding: 32px 15px 0px;
}

.sp_product_thumb .batch {
  position: absolute;
  top: 25px;
  right: 15px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  background: var(--main-theme-color);
  color: #fff;
  padding: 6px 6px;
  min-width: 45px;
  text-align: center;
  z-index: 1;
  line-height: 1;
}

.sp_product_thumb a {
  position: relative;
  display: block;
}

.sp_product_thumb img {
  max-width: 100%;
}

.sp_product_content {
  padding: 25px 40px 25px;
}

.sp_product_content .rating_sp {
  color: var(--main-theme-color);
  font-size: 10px;
  letter-spacing: -0.5px;
  margin-bottom: 4px;
}

.sp_product_content h6 {
  font-size: 16px;
  font-weight: 700;
  color: #515457;
  line-height: 1.4;
  margin-bottom: 7px;
}

.sp_product_content .product_status {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  color: var(--main-theme-color);
}

.sp_product_content .sp_cart_wrap {
  width: 132px;
  margin: 15px auto 15px;
}

.sp_product_content .sp_cart_wrap .cart_plus_minus {
  position: relative;
}

.sp_product_content .sp_cart_wrap input {
  width: 100%;
  background: #ebebeb;
  border: none;
  padding: 10px 50px;
  font-size: 14px;
  font-weight: 700;
  color: #41464c;
  text-align: center;
  border-radius: 50px;
}

.best_sellers_products .sp_product_item .cart_plus-minus .qtybutton {
  background: #f2f4f7;
  border-color: #fff;
}

.sp_product_content .cart_plus_minus .qtybutton.dec {
  font-size: 20px;
}

.sp_product_content .cart_plus_minus .qtybutton.inc {
  left: auto;
  right: 0;
}

.sp_product_content .cart_plus_minus .qtybutton {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #ebebeb;
  cursor: pointer;
  z-index: 1;
  font-weight: 800;
  font-size: 16px;
  font-family: var(--font-family-heading);
  color: var(--color-secondary);
  user-select: none;
}

.sp_product_content > p {
  margin-bottom: 0;
  font-size: 14px;
  color: #515457;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
}

.grocery_todays_area_left {
  background-color: #f2f4f7;
  text-align: center;
  padding: 37px 18px;
  border-radius: 5px;
}

.grocery_todays_img {
  padding-top: 30px;
}

.grocery_todays_content {
  padding-top: 30px;
}

.grocery_todays_content h3 {
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}

.grocery_todays_content p {
  padding-bottom: 20px;
}

#todays_deails_area .sp_product_thumb img {
  display: inline-block;
  max-width: 100%;
  width: 100px;
}

#todays_deails_area .sp_product_content {
  padding: 20px 20px 23px;
}

#todays_deails_area .sp_product_content h6 {
  font-size: 14px;
  margin-bottom: 5px;
}

#todays_deails_area .sp_product_content .cart_plus_minus .qtybutton {
  width: 35px;
  height: 35px;
}

#todays_deails_area .sp_product_content .sp_cart_wrap input {
  padding: 5px 50px;
  min-height: 35px;
}

#todays_deails_area .sp_product_item {
  margin-bottom: 25px;
}

.grocery_cat_wrapper {
  background-image: url("../img/grocery/banner/grocery_cta_bg.png");
  background-repeat: no-repeat;
  padding-top: 30px;
}

.grocery_cta_content {
  padding-left: 40px;
}

.grocery_cta_content h2 {
  line-height: 50px;
}

.grocery_cta_content p {
  padding-top: 15px;
}

.grocery_cta_content form {
  padding-top: 30px;
}

.grocery_cta_content .input-group {
  width: 75%;
}

.btn_cta {
  border: none;
  background: var(--main-theme-color);
  width: 56px;
  height: 44px;
  line-height: 42px;
  font-size: 19px;
  color: #fff;
  border-radius: 0 6px 6px 0;
}

.grcery_support_boxed {
  display: flex;
  background: #f1f2ff94;
  padding: 30px 20px;
  border-radius: 5px;
  transition: 0.4s all ease-in-out;
}

.grcery_support_boxed:hover {
  transform: translateY(-10px);
}

.support_boxed_grocery_content {
  padding-left: 20px;
}

.support_boxed_grocery_content h5 {
  font-size: 18px;
  font-weight: 600;
}

.support_boxed_grocery_content p {
  padding-top: 2px;
  font-size: 14px;
}

/* ------------------------------------
        Pharmacy Page
--------------------------------------*/
#pharmacy_banner {
  padding: 50px 0 0 0;
  position: relative;
}

.pharmacy_banner_img img {
  width: 100%;
}

#pharmacy_categorie {
  position: relative;
}

.pharmacy_top_card {
  padding: 20px 20px;
  border: 1px solid #e2e2e2;
  text-align: center;
  transition: 0.4s all ease-in-out;
}

.pharmacy_top_card:hover {
  border: none;
  background: #ebebeb;
  border: 1px solid #e2e2e2;
}

.pharmacy_top_card a h4 {
  font-size: 18px;
  padding-top: 10px;
}

.slider_button_left_right .owl-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
}

.slider_button_left_right .owl-carousel .owl-nav .owl-next,
.slider_button_left_right .owl-carousel .owl-nav .owl-prev {
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  color: #000;
  box-shadow: 0 1px 15px 0 rgb(0 0 0 / 10%);
  transition: 0.4s all ease-in-out;
  border: 1px solid #dbd9d9;
}

.slider_button_left_right .owl-carousel .owl-nav .owl-next:hover,
.slider_button_left_right .owl-carousel .owl-nav .owl-prev:hover {
  color: var(--main-theme-color);
}

.view_all_arae {
  position: absolute;
  top: 20px;
  right: 0;
}

.view_all_arae a {
  color: var(--main-theme-color);
  font-size: 18px;
  font-weight: 500;
}

.view_all_arae a:hover {
  color: var(--heading-color);
}

#pharmacy_featured_product {
  position: relative;
}

.tabs_left_button .nav-tabs {
  border-bottom: none;
  width: 100%;
  justify-content: end;
  position: absolute;
  right: 15px;
  top: -70px;
}

.tabs_left_button .nav-tabs li:first-child a {
  margin-left: 0px;
}

.tabs_left_button .nav-tabs li .active {
  color: var(--main-theme-color) !important;
}

.tabs_left_button .nav-tabs li a {
  margin-left: 50px;
  transition: inherit;
  background: transparent;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.pharmacy_product_card {
  position: relative;
  border: 1px solid #d2d2d285;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}

.pharmacy_product_img {
  position: relative;
}

.pharmacy_product_img a img {
  width: 100%;
}

.pharmacy_product_img .batch {
  position: absolute;
  top: 15px;
  right: 20px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  background: var(--main-theme-color);
  color: #fff;
  padding: 4px 11px;
  min-width: 45px;
  text-align: center;
  z-index: 1;
  line-height: 1;
}

.pharmacy_product_text {
  text-align: center;
  background: #fff;
  padding: 17px 10px;
}

.pharmacy_product_text p {
  padding-top: 7px;
  font-size: 20px;
  color: var(--main-theme-color);
  font-weight: 500;
}

.pharmacy_product_text p del {
  padding-left: 10px;
  color: #f798378c;
}

.pharmacy_product_icon {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all 0.5s ease-in-out;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pharmacy_product_icon ul {
  display: table;
  margin: 0 auto;
}

.pharmacy_product_icon ul li {
  position: relative;
  display: inline-block;
  background: #fff;
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}

.pharmacy_product_icon ul li:last-child a {
  border-right: none;
}

.pharmacy_product_icon ul li a {
  line-height: 45px;
  padding: 10px 10px;
  border-right: 1px solid #dedede;
}

.pharmacy_product_card:hover .pharmacy_product_icon li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

#pharmacy_date_month {
  background: #ebebeb;
}

.pharmacy_date_img {
  margin-bottom: 30px;
}

#pharmacy_date_month ul li span {
  display: block;
  font-size: 24px;
  font-weight: 600;
}

#pharmacy_grocery {
  text-align: center;
  padding-top: 30px;
}

#pharmacy_grocery ul li {
  display: inline-grid;
  background: #fff;
  width: 85px;
  border-top: 2px solid var(--main-theme-color);
  padding: 15px 12px;
  font-size: 13px;
  margin-right: 13px;
  text-align: center;
}

#pharmacy_grocery ul li span {
  display: block;
  font-size: 24px;
  font-weight: 600;
}

.pharmacy_time_area {
  margin-bottom: 40px;
  text-align: center;
}

.pharmacy_time_area h2 {
  padding-bottom: 15px;
}

.pharmacy_date_img img {
  width: 100%;
}

.pharmacy_promo_img {
  margin-bottom: 30px;
}

.pharmacy_promo_img img {
  width: 100%;
}

.pharmacy_heading_wrapper {
  position: relative;
}

.partner_logo_pharmacy {
  position: relative;
  z-index: 999;
}

.partner_logo_pharmacy img {
  width: 60% !important;
  margin: 0 auto !important;
}

#pharmacy_footer_top {
  background: #ebebeb;
  padding: 80px 0;
}

.pharmacy_footer_text {
  text-align: center;
}

.pharmacy_footer_text p {
  padding-top: 10px;
  font-size: 18px;
}

.pharmacy_footer_text {
  padding-bottom: 20px;
}

/* ----------------------------
       Jewellary Page
-------------------------------*/
#jewellry_banner {
  background-image: url("../img/jewellary/banner/banner.png");
  padding: 160px 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.jewellry_banner_text h1 {
  font-size: 65px;
  font-weight: 500;
  color: #fff;
  line-height: 75px;
  padding-bottom: 15px;
}

.jewellry_banner_text h5 {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  padding-bottom: 15px;
}

.jewellry_banner_text h5 span {
  color: #000;
}

.jewellry_banner_text a {
  margin-top: 30px;
  font-size: 18px;
}

.hover_area_translateY {
  transition: 0.4s all ease-in-out;
}

.hover_area_translateY:hover {
  transform: translateY(10px);
}

.jewellary_offer_topimg img {
  width: 100%;
}

.jewellry_top_twoimg {
  padding-bottom: 30px;
}

.jewellry_top_twoimg img {
  width: 100%;
}

.jewellary_heading {
  text-align: center;
  padding-bottom: 70px;
}

.jewellary_heading h2 {
  position: relative;
}

.jewellary_heading h2::after {
  content: "";
  position: absolute;
  width: 250px;
  height: 3px;
  background: var(--main-theme-color);
  left: 50%;
  bottom: -26px;
  transform: translate(-50%, -50%);
  border-radius: 15px;
}

.jewellary_heading h2::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background: var(--main-theme-color);
  left: 50%;
  bottom: -40px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 4px solid #fff;
  z-index: 99;
}

.jewellary_product_card {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.jewellary_product_img {
  position: relative;
  background: #eeeeee;
  padding: 18px;
}

.jewellary_product_img a img {
  width: 100%;
}

.jewellary_product_text {
  padding-top: 25px;
}

.jewellary_product_text p {
  padding: 10px 0;
}

.jewellary_product_text h4 {
  color: var(--main-theme-color);
}

.jewellary_product_icon {
  position: absolute;
  top: 65%;
  left: 20px;
  z-index: 2;
  right: 0;
  text-align: center;
  padding-top: 0;
  transition: all 0.5s ease-in-out;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.jewellary_product_icon ul {
  display: table;
}

.jewellary_product_icon ul li {
  position: relative;
  background: #fff;
  -webkit-transform: translate(0, 15px);
  -ms-transform: translate(0, 15px);
  -o-transform: translate(0, 15px);
  transform: translate(0, 15px);
  opacity: 0;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  margin-bottom: 5px;
}

.jewellary_product_icon ul li:last-child a {
  border-right: none;
}

.jewellary_product_icon ul li a {
  line-height: 45px;
  padding: 10px 10px;
}

.jewellary_product_card:hover .jewellary_product_icon li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.dots_style .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 5px 7px;
  background: #86979170;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.dots_style .owl-theme .owl-dots .owl-dot.active span,
.dots_style .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--main-theme-color);
}

#paralex_area {
  background-image: url("../img/jewellary/banner/paralex.png");
  padding: 145px 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
}

.paralex_text {
  text-align: center;
}

.paralex_text h3 {
  color: #fff;
}

.paralex_text h2 {
  font-size: 60px;
  color: #fff;
  padding: 30px 0;
}

.paralex_text p {
  color: #fff;
  padding: 0 155px;
  font-size: 18px;
  padding-bottom: 30px;
}

#explore_product_jewellary .tabs_center_button {
  padding-bottom: 40px;
}

#jewellary_customer_service {
  background: #f4f4f4;
  padding: 100px 0;
}

.jewel_serv_card {
  text-align: center;
}

.jewel_serv_card i {
  font-size: 40px;
  padding-bottom: 20px;
}

.jewel_serv_card h4 {
  padding-bottom: 5px;
}

.jewellary_blog_card {
  text-align: center;
}

.jewellary_blog_img {
  overflow: hidden;
}

.jewellary_blog_img img {
  transition: 0.4s all ease-in-out;
  width: 100%;
}

.jewellary_blog_card:hover .jewellary_blog_img img {
  transform: scale(1.2);
}

.jewellary_blog_content {
  padding-top: 25px;
}

.jewellary_blog_content h3 {
  padding-bottom: 15px;
}

.jewellary_blog_card:hover .jewellary_blog_content h3 a {
  color: var(--main-theme-color);
}

.jewellary_blog_content h6 {
  font-size: 16px;
}

.jewellary_blog_content h6 a {
  color: var(--main-theme-color);
}

.jewellary_blog_content p {
  padding-top: 15px;
  padding-bottom: 15px;
}

.jewellary_blog_content .read_more {
  color: var(--main-theme-color);
}

.grocery_banner_img img {
  width: 100%;
}

/* ----------------------------
       Baby Shop Page
-------------------------------*/

#baby_banner {
  padding: 50px 0;
}

.baby_banner_img img {
  width: 100%;
}

.baby_category_card {
  text-align: center;
  position: relative;
  margin-top: 70px;
  transition: 0.4s all ease-in-out;
}

.baby_category_card:hover {
  transform: translateY(-10px);
}

.baby_cat_img {
  position: absolute;
  top: -57px;
  left: 50%;
  transform: translate(-50%);
}

.baby_cat_content {
  border: 1px solid #dcdcdc;
  padding: 70px 20px 20px 20px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.baby_cat_content p {
  padding-top: 10px;
}

#baby_trending_product .tabs_center_button {
  padding-bottom: 40px;
}

#baby_offer_banner {
  padding: 150px 0;
  background-image: url("../img/baby-shop/banner/offer_bg.jpg");
  background-repeat: no-repeat;
}

.baby_offer_banner_text {
  text-align: center;
}

.baby_offer_banner_text h2 {
  color: #fff;
  padding-bottom: 60px;
  font-size: 55px;
}

.baby_todays_count {
  padding-top: 40px;
}

.baby_blog_thumb a img {
  width: 100%;
  filter: invert(0.2);
  transition: 0.4s all ease-in-out;
}

.baby_blog_card:hover .baby_blog_thumb a img {
  filter: invert(0);
}

.baby_blog_content {
  padding-top: 20px;
}

.baby_blog_content span {
  padding-bottom: 5px;
  display: block;
  font-size: 14px;
  color: #8d8d8d;
}

.baby_blog_content h3 {
  padding-top: 10px;
}

.baby_blog_content p {
  padding-top: 10px;
  padding-bottom: 20px;
}

.baby_blog_content .read_more {
  color: var(--main-theme-color);
}

.baby_offer_one_img a img {
  width: 100%;
}

/* ----------------------------
     Cart 3 Page
-------------------------------*/
#cart_area_three .coupon_code {
  margin-top: 0;
}

#cart_area_three .cart_submit {
  padding: 20px 0 0 0;
}

#total_cart_form_three .rigth_cart {
  display: inline-block;
  float: right;
}

.total_cart_inner {
  padding: 20px 20px;
}

.total_cart_inner h5 {
  padding-bottom: 20px;
  font-weight: 700;
}

.estimate_for_country {
  padding-top: 10px;
}

.total_catr_three_bottom {
  margin-top: 30px;
  border-top: 1px solid #e7e7e7;
  padding-top: 12px;
}

.total_catr_three_bottom h5 {
  font-weight: 500;
}

.total_catr_three_bottom h5 span {
  float: right;
}

.bottom_code {
  margin-top: 30px !important;
}

/* ----------------------------
    Product Details 3 Page
-------------------------------*/
#product_single_three .list_three_pro li {
  padding-bottom: 10px;
}

#product_single_three .list_three_pro li span {
  padding-right: 10px;
}

#product_single_three .list_three_pro {
  padding-top: 23px;
}

#product_single_three .customs_selects {
  padding-top: 30px;
}

#product_single_three .variable-single-item {
  padding-top: 10px;
}

#product_single_three .reviews_rating {
  padding-top: 15px;
}

#product_single_three .modal_product_content_one h4 {
  padding-top: 20px;
}

#product_single_three .modal_product_content_one h4 del {
  padding-left: 20px;
  color: var(--main-theme-color);
}

#product_single_three .modal_product_content_one p {
  padding-top: 20px;
}

#product_single_three .links_Product_areas ul li {
  display: inline-flex;
  padding-right: 20px;
}

#product_single_three .links_Product_areas ul li a i {
  padding-right: 5px;
}

#product_single_three .links_Product_areas .theme-btn-one {
  margin-top: 20px;
}

.align_text_color {
  font-weight: 600;
  text-transform: capitalize;
  color: #24262b;
  position: relative;
  top: -9px;
  padding-right: 15px;
}

.product_details_cat {
  padding-top: 20px;
}

.product_details_cat ul li {
  display: inline-flex;
  font-weight: 600;
  text-transform: capitalize;
  color: #24262b;
  position: relative;
  top: -9px;
  padding-right: 8px;
}

.product_details_cat ul li a {
  font-weight: 400;
}

.product_details_bor {
  border-bottom: 1px solid #efefef;
}

.area_custom_details {
  display: flex;
  align-items: center;
}

.product_detail_socials {
  padding-top: 15px;
}

.product_detail_socials ul li {
  padding-left: 10px;
}

.product_detail_socials ul li a {
  display: inline-block;
  height: 36px;
  width: 36px;
  background: #ddd;
  text-align: center;
}

.product_detail_socials ul li a i {
  font-size: 18px;
  line-height: 36px;
  color: var(--heading-color);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.product_detail_socials ul li:hover a {
  background-color: var(--main-theme-color);
}

.product_detail_socials ul li:hover a i {
  color: #fff;
}

.product_detail_socials h6 {
  font-size: 16px;
  font-weight: 700;
}
