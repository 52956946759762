@keyframes productPageHeroSlider {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}
.productPageHeroSlider {
  animation: productPageHeroSlider 20s ease-in-out infinite;
  animation-duration: 20s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: productPageHeroSlider;
}

.plane_bg {
  background-image: url("../../assets/img/common/plane.png");
  background-position: center;
  background-repeat: no-repeat;
}

.bus_bg {
  background-image: url("../../assets/img/common/bus2.png");
  background-position: center;
  background-repeat: no-repeat;
}
